@mixin edit-delete-btns {
  &:nth-child(1) button {
    width: 85px !important;
    height: 36px;
    padding: 6px 30px 4.1px 1.9px !important;
    font-family: Barlow;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    z-index: 0;
  }
  & > .btn:not(:first-child) {
    width: 65px;
    height: 36px;
    margin-left: -36px;
    padding: 6px 0px 4.1px 0px !important;
    padding-top: 6px !important;
    padding-right: 0px !important;
    padding-bottom: 4.1px !important;
    padding-left: 0px !important;
    border-radius: 22px;
    background-color: #db554d;
    font-family: Barlow;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
}

.kais-blue {
  color: #698aff;
}

.c-header,
.c-subheader {
  background-color: #4a60da;
  border: 0 !important;
}

.c-header {
  height: 59px;
}

.c-subheader {
  height: 65px;
}

.header-toggle_mobile {
  margin-top: 11px !important;
  margin-left: 20.5px !important;
  margin-bottom: 1px;
  padding: 0 !important;
  outline: 0 !important;
  border: 0 !important;
  background-color: inherit !important;

  svg {
    width: 25px;
    height: 25px;
  }
}

.header_li-dashboard {
  height: 100%;
  display: flex;
}

.header_link-dashboard {
  font-family: MyriadPro !important;
  font-size: 28px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.2 !important;
  letter-spacing: normal !important;
  color: #fff !important;
  margin: auto auto -4px 15px;
  padding: 0 !important;
}

.breadcrumb_header-wrapper {
  margin-top: -25px !important;
  ol {
    padding-left: 1px !important;
    display: flex;
    align-items: end;
    padding-bottom: 0px;
    a,
    li:before,
    li.active.breadcrumb-item {
      font-family: MyriadPro;
      font-size: 14px !important;
      font-weight: normal !important;
      font-stretch: normal !important;
      font-style: normal !important;
      line-height: 1.2 !important;
      letter-spacing: normal !important;
      text-align: left !important;
      color: #fff !important;
    }
  }
}

.messages_notification-header {
  svg {
    width: 35px;
    height: 30px;
  }
  .badge.badge-info.badge-pill {
    font-size: 10px;
    padding: 2px 3px 10px 2px !important;
    width: 12px !important;
    height: 12px !important;
    p {
      margin: 0 !important;
    }
  }
}
html:not([dir="rtl"]) .c-header-nav .c-header-nav-link .badge,
html:not([dir="rtl"]) .c-header-nav .c-header-nav-btn .badge {
  margin-right: -3px !important;
}

.messages_notification-header_ul {
  position: absolute;
  margin-bottom: 0;
  z-index: 5;
  list-style: none;
  top: -70px;
  left: 144px;
  .badge {
    font-family: "Barlow", sans-serif;
    top: 15px !important;
    display: flex !important;
    justify-content: center !important;
    vertical-align: middle !important;
    background-color: #392b4c !important;
    right: 0;
    left: inherit !important;
    width: 20px !important;
    height: 20px !important;
    border: solid 1px #fff !important;
    p {
      margin: auto;
    }
  }
}

.brand_wrapper-header {
  background: #4a60da;
  margin-right: 10px;
  margin-left: 70px;
  height: 100px;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  &:hover {
    color: #fff !important;
  }
}

.header_main-wrapper:not(.z-index-removed) {
  height: 60px;
}
.c-wrapper {
  background-color: #fff;
  &:not(.c-wrapper-fluid) .c-header-fixed {
    position: fixed !important;
  }
  &.sidebar_setted .c-header-fixed {
    left: 200px;
  }
}

html:not([dir="rtl"])
  .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed
  ~ .c-wrapper,
html:not([dir="rtl"])
  .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed
  ~ .c-wrapper {
  margin-left: 201px !important;
}

.c-sidebar-minimized {
  .c-sidebar-brand * {
    visibility: hidden;
  }
}

.brand_message_notification-wrapper {
  display: flex;
  justify-content: flex-end;
  height: 60px;
}

.header_brand-image {
  width: 85px;
  height: 70px;
  margin-right: 17px;
  margin-top: 11px;
  z-index: 1;
}

.header_brand-name {
  font-family: GoodTimesRg;
  font-size: 36px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  margin-top: -27px;
}

.user_info-header {
  height: 75px !important;
  background-color: #4a60da !important;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
}

.dash_avatar {
  position: absolute;
}

.dash_avatar {
  // background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='white' stroke-width='6' stroke-dasharray='40%25%2c 14%25' stroke-dashoffset='80' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 100px;
  top: 10px;
  left: 30px;
  width: 60px;
  height: 60px;
  background-color: #4a60da !important;
  z-index: 0 !important;
  &::after {
    content: "";
    position: absolute;
    width: 110px;
    height: 10px;
    z-index: 1;
    background-color: #4a60da !important;
    bottom: 0;
    right: 0;
    overflow: visible;
  }
}

.avatar-header {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #b13573;
  border: 4px solid #e8e8e8;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
}

.avatar_letter {
  font-family: Barlow;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  margin-bottom: 5px;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.avatar_user_name-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  position: absolute;
  left: 26px;
  top: 18px;
}

.c-sidebar {
  width: 200px !important;
}

html:not([dir="rtl"]) .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right),
html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right) {
  margin-left: 0 !important;
}
html:not([dir="rtl"]) .c-sidebar:not(.c-sidebar-right) {
  margin-left: -250px !important;
}
// html:not([dir="rtl"]) .c-sidebar:not(.c-sidebar-right) {
//   margin-left: -325px;
// }

.sidebar_custom-header {
  margin-top: 75px;
  background-color: #f8f8f8;
}
.sidebar_custom-header:not(.responsive) {
  z-index: 1 !important;
  // .user_info-header {
  //   display: none !important;
  // }
}
.sidebar_custom-header.responsive {
  z-index: 100 !important;
}

.username_userclass-wrapper {
  text-align: center;
  display: block;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-family: Barlow;
  margin-left: 20px;
  overflow: hidden;
  color: #fff;
  margin-top: 20px;
  margin-bottom: auto;

  h5 {
    overflow: hidden;
    margin-bottom: 0;
    font-size: 14px;
  }
  p {
    margin-bottom: 0;
    margin-left: 2px;
    display: inline;
  }
}

.c-sidebar-nav {
  margin-top: 23px;
  // background-color: #fff;
}

.c-sidebar-nav-link {
  font-family: Barlow-Bold;
  font-size: 12px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.2 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: #392b4c !important;
  user-select: none;
  &:hover {
    background-color: #e2e2e2 !important;
  }
  svg,
  img {
    margin-left: 15px;
    margin-right: 15px;
    width: 25px;
    height: 25px;
  }
  img {
    filter: invert(14%) sepia(8%) saturate(4277%) hue-rotate(224deg)
      brightness(88%) contrast(84%);
  }
}

.c-sidebar-nav,
.c-sidebar-nav-dropdown-items {
  .c-sidebar-nav-item {
    .c-sidebar-nav-link {
      &.c-active {
        color: white !important;
        background: #e2e2e2;

        svg path,
        svg circle {
          fill: white;
        }
      }
    }
  }
}

.c-sidebar-nav-dropdown:not(.c-show) .c-sidebar-nav-item .c-sidebar-nav-link {
  margin-left: 67px !important;
}

.c-sidebar-nav-dropdown.c-show {
  background-color: #eeebeb !important;

  .c-sidebar-nav-dropdown-toggle {
    border-bottom: 1px solid #bababa;
    color: #4a60da !important;

    svg path,
    svg circle {
      fill: #4a60da;
    }
  }

  .c-sidebar-nav-dropdown-items {
    .c-sidebar-nav-item {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 7px;
        height: 7px;
        background: #392b4c;
        z-index: 1;
        border-radius: 50%;
        left: 25%;
        top: 50%;
        transform: translateY(-75%);
      }
      .c-sidebar-nav-link {
        padding: 0.65rem 1rem 0.65rem 75px !important;
        position: relative;

        &.c-active {
          color: white !important;

          &::before {
            background: white;
          }
        }
      }
      svg {
        display: none;
      }
    }
  }
}

.c-sidebar-nav-link {
  padding: 0.5rem !important;
}

html:not([dir="rtl"])
  .c-sidebar-nav-dropdown.c-show
  > .c-sidebar-nav-dropdown-toggle::after {
  transform: rotate(270deg) !important;
}

.c-sidebar .c-sidebar-nav-dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.335' height='18.498' viewBox='0 0 10.335 18.498'%3E%3Cpath id='Контур_2922' data-name='Контур 2922' d='M4484,351.3l2.821-.109-5.975-8.4,5.3-8.942-2.821.109-5.3,8.942Z' transform='translate(-4477.433 -333.308)' fill='%23392b4c' stroke='%236588ff' stroke-miterlimit='10' stroke-width='1'/%3E%3C/svg%3E%0A") !important;
  height: 18.5px;
  width: 10.5px;
  margin-right: 10px;
  background-position: center;
  background-size: contain;
}

.input_without_z-index,
.button_without_z-index {
  z-index: 0 !important;
}

@media (min-width: 1200px) {
  .loads_tabs-info__driver-info {
    display: inline-flex;
    justify-content: right;
    margin-left: 40px;
  }
}

@media (max-width: 1700px) {
  .brand_wrapper-header {
    margin-left: 20px;
  }
}

@media (min-width: 991px) {
  .md-flex_no_wrap {
    display: flex;
    flex-wrap: nowrap;
  }
  .edit_data_bordered-wrapper:not(.load_driver_edit_wrapper):not(.load_expenses_edit_wrapper):not(.tabs_exist)
    .card-body.pt-100px {
    padding-top: 85px !important;
  }
  .button_switch-wrapper.isSold.t-65px.l-0 {
    left: auto !important;
    right: 600px !important;
  }
  .button_switch-wrapper.isSold.t-65px.po_trl {
    right: 300px !important;
  }
  .button_switch-wrapper.isSold {
    top: 26px !important;
    right: 100px;
  }
  .rate_confirmation_generate_button {
    top: 58px !important;
    right: 0;
  }
  .prices_summ_loads_page-wrapper {
    top: 0px;
    left: -20px;
    span:nth-child(1) {
      border-top-left-radius: 15px;
      padding: 14px 15px 3px 15px;
    }
  }
  .prices_summ_loads_page-wrapper span {
    font-weight: 700 !important;
    box-shadow: 1px 1px 3px white;
    &:nth-child(2) {
      border-bottom-right-radius: 14px;
      padding: 3px 15px 12px 15px;
    }
  }
}
@media (max-width: 991px) {
  .load_drivers_switch .c-switch-slider {
    width: 60px;
  }
  .slider-modal + .modal-backdrop.fade.show,
  .file_upload_main-div + .modal-backdrop.fade.show,
  .modal_left-0 + .modal-backdrop.fade.show {
    left: 0;
  }
  .sidebar_custom-header {
    z-index: 1 !important;
    margin-top: 0 !important;
  }
  html:not([dir="rtl"])
    .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right):not(:hover),
  html:not([dir="rtl"])
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right):not(:hover) {
    margin-left: -165px !important;
    a {
      font-size: 0 !important;
    }
    svg {
      margin-left: auto !important;
      margin-right: 0 !important;
    }
    .c-sidebar-nav-dropdown-toggle::after {
      display: none;
    }
  }
  html:not([dir="rtl"])
    .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right):hover,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right):hover {
    margin-left: 0 !important;
  }
  html:not([dir="rtl"]) .c-sidebar:not(.c-sidebar-right) {
    margin-left: -325px !important;
  }
  html:not([dir="rtl"])
    .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed
    ~ .c-wrapper,
  html:not([dir="rtl"])
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed
    ~ .c-wrapper {
    margin-left: 40px !important;
  }

  .header_brand-name {
    font-size: 30px;
  }
  .messages_notification-header,
  .messages_notification-header svg {
    width: 50px;
    height: 41px;
  }
  .main_content_wrapper.sidebar_setted {
    width: calc(100% - 40px) !important;
  }
  .c-wrapper .c-header-fixed {
    left: 0 !important;
    padding-left: 30px;
  }
  html:not([dir="rtl"]) .c-sidebar {
    z-index: 2000 !important;
  }
  .header-bottom_part {
    left: 0 !important;
    width: 100% !important;
  }
  .filter_toggled_wrapper {
    height: auto !important;
    padding-top: 50px !important;
    margin-bottom: 20px;
    .col-sm-6 {
      justify-content: center !important;
      svg {
        margin: auto 5px !important;
      }
    }
    .filter-collumns_wrapper {
      padding-bottom: 15px;
    }
  }
}

@media (max-width: 768px) {
  .header_link-dashboard {
    font-size: 28px !important;
    margin-top: 32px !important;
  }
}
@media (min-width: 768px) {
  form.fuel_form_on_left {
    transform: translate(calc(-100% - 30px), calc(-100% + 60px));
    &.translate-y-140 {
      transform: translate(calc(-100% - 30px), calc(-100% - 220px));
    }
  }
  form.form-translate-y-200 {
    transform: translate(0, -200px);
  }
  form.form-translate-y-300 {
    transform: translate(0, -300px);
  }
}

@media (max-width: 576px) {
  .brand_wrapper-header {
    margin-right: 0 !important;
    margin-left: 5px !important;
  }

  .breadcrumb_header-wrapper ol a,
  .breadcrumb_header-wrapper ol li:before,
  .breadcrumb_header-wrapper ol li.active.breadcrumb-item {
    font-size: 12px !important;
  }
  .messages_notification-header_ul {
    padding: 0 !important;
  }
  .fixed_table .buttons_count_1.type_excell_download {
    justify-content: end !important;
    .space_around-wrapper {
      justify-content: end !important;
      margin: 0 30px 0 0 !important;
    }
  }
}

@media (max-width: 500px) {
  .brand_wrapper-header {
    display: none;
  }
  .brand_message_notification-wrapper {
    justify-content: flex-end;
  }
}

.main_content_wrapper {
  position: absolute;
  top: 63px;
  z-index: 1 !important;
  width: 100%;

  &.dashboard {
    top: 60px;
  }

  &.without_sidebar {
    right: 0;
    transition: all 0.2s ease-in-out;
  }
  &.sidebar_setted {
    width: calc(100% - 200px);
  }
  .fixed_table,
  .border-radius_wrapper {
    border-top-left-radius: 22px;
    border-top-right-radius: 22px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    &:not(.height-auto) .active.show {
      width: 100%;
    }
  }
}

.c-main {
  padding-top: 0 !important;
}

.default-layouts {
  .c-body {
    .modal.show {
      .modal-dialog {
        margin-top: 100px;
      }
    }
  }
}

.download_excell {
  border-radius: 9.2px;
  box-shadow: 2px 2px 1px 0 rgba(0, 0, 0, 0.25);
  background-color: #ececec;
}

.add_data_table,
.add_button_without-link {
  border-radius: 9.2px;
  box-shadow: 2px 2px 1px 0 rgba(0, 0, 0, 0.25);
  background-color: #4a60da;
}
.add_button_without-link {
  margin-top: 0 !important;
}

.tables_buttons-wrapper {
  position: absolute;
  top: 16px;
  left: 0;
  display: flex;
  justify-content: space-around;
  width: 100%;
  .space_around-wrapper {
    justify-content: space-evenly;
  }
  .download_excell,
  a,
  .add_button_without-link {
    width: 295px;
    max-width: 40%;
    height: 45.5px;
    cursor: pointer;
    z-index: 1 !important;
  }
  .add_data_table {
    width: 100%;
    height: 100%;
  }
}

.fixed_table {
  overflow: hidden;
  padding-bottom: 0 !important;
  max-width: 100%;
  margin-right: auto;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  ::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: #e2e2e2;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #4a60da;
  }

  &.card {
    margin-bottom: 0;
  }

  .card-body {
    padding: 1rem 14px;
  }

  .custom-table_pagination {
    padding-top: 10px;
    margin-top: 0;
  }

  .table-sm th {
    padding-bottom: 1rem;
    padding-top: 0;
  }

  .row.my-2.mx-0 {
    display: flex;
    vertical-align: middle;
    align-items: center;
  }

  .select_status {
    font-size: 14px;
    font-weight: 600;
    color: white;

    li span:first-child {
      margin-top: 0px;
    }
  }

  .tables_buttons-wrapper .download_excell,
  .tables_buttons-wrapper a,
  .tables_buttons-wrapper .add_button_without-link {
    width: fit-content;
    height: auto;
  }

  label {
    font-family: SegoeUI, Arial;
    font-size: 14px;
    padding-top: 5px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    &:not(.color_radio_label) {
      color: #392b4c;
    }
  }
  select:first-of-type {
    color: #392b4c;
  }
  input:first-of-type,
  select:first-of-type {
    margin-top: 5px !important;
    border-radius: 9.2px;
    background-color: #ececec;
  }
  input[name="address"] {
    max-width: 100% !important;
  }
  thead th {
    white-space: nowrap;
    margin: 0 31.7px 16.2px 23px;
    font-family: Barlow-Bold, Arial;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #392b4c;
    input:not([type="text"]),
    select {
      margin: 0;
      font-family: "Barlow";
      font-size: 14px;
      text-align: center;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: #392b4c;
      margin: auto;

      option {
        max-width: 150px;
      }
    }
    select option {
      font-size: 14px !important;
    }
    input[type="text"] {
      text-align: center;
      font-family: MyriadPro;
      font-weight: normal;
      font-size: 14px;
      padding: 8px;
      border: 0;
      &:hover {
        border-width: 1px;
      }
      &:nth-of-type() {
        margin-left: 2px;
        margin-right: 2px;
      }
    }

    .react-datepicker__input-container {
      input {
        font-size: 13px;
        border: 0;
      }
    }
  }
  thead tr:nth-child(2) {
    th {
      margin-right: 5px !important;
      margin-left: 5px !important;
    }
  }
  tbody {
    white-space: nowrap;
    font-family: Barlow;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.03;
    letter-spacing: normal;
    text-align: center;

    td {
      vertical-align: middle;
    }
  }
}

.c-header.c-header-fixed:not(.z-index-removed) {
  z-index: 99 !important;
}
.c-header.c-header-fixed.z-index-removed {
  z-index: 0 !important;
}
.header-top_part {
  background-color: #4a60da;
  height: 60px;
}
.without_sidebar .container-fluid {
  padding: 0 15px 0 0 !important;
}

.header-bottom_part {
  z-index: 0;
  position: fixed;
  width: calc(100% - 200px);
  left: 200px;
  height: 75px;
  top: 0;
  background-color: #4a60da !important;
}

.without_sidebar .header-bottom_part {
  width: 100% !important;
  left: 0 !important;
}

.c-sidebar .c-sidebar-brand {
  border-right: 0 !important;
}
header .c-sidebar-brand {
  transform: scale(0.68);
  margin-top: -24px !important;
}

@media (max-width: 2000px) {
  .tables_buttons-wrapper {
    .download_excell {
      margin-right: 24px;
    }
    .space_around-wrapper {
      justify-content: center;
    }
  }
}

@media (max-width: 1600px) {
  .tables_buttons-wrapper {
    .download_excell,
    a,
    .add_button_without-link {
      width: 200px;
      font-size: 0.8rem;
    }
    .download_excell {
      margin-right: 10px;
    }
  }
  .fixed_table label {
    font-size: 14px;
  }
  .fixed_table {
    thead th {
      font-size: 14px !important;
    }
    thead input,
    thead select {
      font-size: 14px !important;
    }
    input[type="text"] {
      height: 32px !important;
    }
  }
  tbody {
    font-size: 14px !important;
    line-height: 1 !important;
    white-space: nowrap;
    td {
      vertical-align: middle !important;
    }
  }
}

.history_tab-pane {
  width: 100%;
  table tbody td {
    text-align: left !important;
  }
}

.load-edit-documents_table {
  tbody td {
    font-size: 14px !important;
    line-height: 1.03 !important;
  }
  thead {
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: 1.2 !important;
  }
  td {
    width: 25% !important;
    font-family: Barlow;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #392b4c;
    button.btn-danger {
      border-radius: 2.9px;
      background-color: #db554d;
      font-family: Barlow;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.84;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
    }
    img {
      aspect-ratio: 16 / 9 !important;
      width: 140px !important;
    }
    select {
      margin: auto;
      min-width: 80%;
      font-family: Barlow;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: #4a60da;
      border-radius: 16.1px;
    }
  }
}

.files-tab_custom-padding {
  padding-top: 40px !important;
}

@media (max-width: 1400px) {
  .tables_buttons-wrapper {
    .download_excell,
    a,
    .add_button_without-link {
      width: 150px;
      font-size: 0.7rem;
    }
  }
}

@media (max-width: 1200px) {
  .loads_tabs-info__driver-info {
    display: none;
  }
  .load-history-toggle {
    display: none;
  }
  .tables_buttons-wrapper {
    .download_excell,
    a,
    .add_button_without-link {
      width: 90px;
      font-size: 0.7rem;
    }
    .download_excell {
      margin-left: 10px;
    }
  }
  .fixed_table label {
    font-size: 14px;
  }
  .fixed_table input:first-of-type,
  .fixed_table select:first-of-type {
    max-width: 95%;
  }
}

.user_avatar-dropdown {
  position: absolute;
  inset: 0px auto auto 0px;
  margin: 0px;
  top: -70px !important;
  right: -14px !important;
}

.buttons_count_1 {
  position: absolute;
  top: 24px;
  left: 0;
  display: flex;
  width: 100%;
  &:not(.type_excell_download) {
    justify-content: end !important;
  }
  &.type_excell_download {
    justify-content: left !important;
  }
  &:not(.type_excell_download) .space_around-wrapper {
    justify-content: end;
  }
  &.type_excell_download .space_around-wrapper {
    justify-content: left;
  }
  .download_excell,
  a {
    width: 295px;
    max-width: 40%;
    height: 45.5px;
    cursor: pointer;
    z-index: 1 !important;

    button {
      height: 80% !important;
    }
  }
  &:not(.type_excell_download) .download_excell,
  &:not(.type_excell_download) a {
    margin-right: 30px !important;
  }
  &.type_excell_download .download_excell,
  &.type_excell_download a {
    margin-left: 30px !important;
  }
  .add_data_table {
    width: 100%;
    height: 100%;
  }
}

.custom-table_pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  ul {
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.25);
    background-color: #4a60da;
    margin-bottom: 0;
    padding-left: 8px !important;
    padding-right: 8px;
    border-radius: 20px;
  }
  .page-item {
    border-radius: 20px;
  }
  .page-item:not(:first-child):not(:last-child):not([role="separator"]) {
    display: flex !important;
    justify-content: center !important;
    vertical-align: middle !important;
    a {
      margin: auto;
      width: 25px;
      z-index: 1;
      border-radius: 50% !important;
      box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.25);
      background-color: #fff;
      padding: 0 !important;
      color: black;
      font-family: MyriadPro;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.3;
      letter-spacing: normal;
      text-align: center;
      color: #000;
      &:focus {
        border: 0 !important;
        margin: 6px 0 !important;
      }
    }
  }
  .active.page-item {
    a {
      background-color: rgb(50, 31, 219) !important;
      color: white !important;
    }
  }
  .page-item:not(:first-child):not(:last-child):not(:nth-last-child(2)) {
    margin-right: 10px;
  }
  li a:focus {
    border: 0 !important;
    outline: 0 !important;
    box-shadow: none !important;
  }
  & li:first-child a,
  & li:last-child a {
    padding: 0 5px 0 0 !important;
    &:after {
      content: "";
      background-image: url("/assets/icons/arrow_left.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      width: 12px;
      height: 16px;
      position: absolute;
      top: calc(50% - 8px);
      left: 5px;
    }
  }
  & li:last-child a {
    padding: 0 0 0 5px !important;
    &:after {
      transform: rotate(180deg);
      right: 2px;
      left: auto;
    }
  }
  & li:first-child,
  & li:last-child {
    width: 25px !important;
    user-select: none;
    cursor: pointer;
    height: 100% !important;
    padding: 0 !important;
    display: flex !important;
    vertical-align: middle !important;
    text-align: center !important;
    align-items: center !important;
    justify-content: center !important;
    background-color: #4a60da !important;
    color: white !important;
    border: 0 !important;
    a {
      width: 25px !important;
      height: 100% !important;
      display: flex !important;
      vertical-align: middle !important;
      text-align: center !important;
      align-items: center !important;
      justify-content: center !important;
      background-color: #4a60da !important;
      color: #4a60da !important;
      border: 0 !important;
    }
  }
}

.dropdown_without_height {
  height: 0 !important;
}

.fixed_table tbody tr td {
  padding: 0.5rem;

  &:first-child {
    padding-left: 1rem;
  }

  .badge {
    min-width: 80px;
    // height: 23px;
    border-radius: 14px;
    padding: 2px !important;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }

  .badge-secondary {
    color: #4c5870;
    padding: 2px 10px 0 10px !important;
  }
  .btn-group {
    &:nth-child(1) a button {
      width: 85px;
      height: 30px;
      padding: 0 42px 1px 1px;
      font-family: Barlow;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.8;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
      z-index: 0;
      border-radius: 0.2rem 22px 22px 0.2rem;
    }
    & > .btn:not(:first-child) {
      width: 85px;
      height: 30px;
      padding: 0 5px 1px 2px;
      font-family: Barlow;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.8;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
    }
    & > .btn:not(:first-child) {
      width: 55px;
      height: 30px;
      margin-left: -45px;
      padding: 0 5px 1px 2px;
      border-radius: 22px;
      background-color: #db554d;
      font-family: Barlow;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.8;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
    }
  }
}

.border-radius_wrapper.tabs-bordered {
  padding: 0;
  height: calc(100vh - 135px);
  .tab-content {
    display: flex;
    justify-content: center;
    vertical-align: middle;
  }
  .nav-tabs {
    display: flex;
    justify-content: space-around;
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    background-color: #392b4c;
    font-family: Barlow;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    border-top-left-radius: 21.9px;
    border-top-right-radius: 21.9px;
    li {
      margin-top: 15px;
      text-transform: uppercase;
    }
    a {
      color: #fff !important;
    }
    .nav-link {
      padding: 0.5rem;
    }
  }
  .nav-tabs > :nth-child(1) .active.nav-link,
  .nav-tabs > :nth-child(1):hover a,
  .nav-tabs > :nth-child(1),
  .nav-tabs {
    border-top-left-radius: 21.9px;
  }
  .nav-link.active,
  .nav-link:hover {
    background-color: #392b4c;
    border: 0;
    color: #4a60da !important;
  }
}

table {
  a {
    color: #4c5870;
  }
}

.edit_add_data-wrapper {
  footer {
    padding: 0 0 34.5px 46px !important;
  }
  .submit_button-tabs {
    padding: 2px 15px;
    border-radius: 8px;
    background-color: #4a60da !important;
    font-family: Barlow;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    margin-right: 22.5px !important;
    box-shadow: none;
    border-color: transparent;
    img {
      width: 20px !important;
      margin-right: 5px !important;
    }
  }
  .reset_button-tabs {
    padding: 2px 15px;
    border-radius: 8px;
    background-color: #b13573 !important;
    font-family: Barlow;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    box-shadow: none;
    border-color: transparent;
    img {
      width: 20px !important;
      margin-right: 5px !important;
    }
  }
  .tab-pane.active.show {
    margin-top: 0;
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      background-color: white;
      background-image: url("/assets/icons/select_default_arrow.png");
      background-repeat: no-repeat;
      background-position-x: calc(100% - 10px);
      background-position-y: calc(50%);
    }
    table {
      font-family: Barlow;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: center;
      color: rgb(57, 48, 76) !important;
      th {
        background-color: rgba(234, 234, 234, 0.5) !important;
      }
    }
  }
  .card {
    margin-bottom: 0;
    border-radius: 23.8px;
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.25);
    background-color: rgba(234, 234, 234, 0.9);
    &.table-bg {
      box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.25);
      background-color: #eaeaea;
    }
    &.history_table-bg {
      box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.25);
      background-color: #eaeaea;
    }
  }
  .history_table-bg .table-responsive {
    height: calc(100vh - 370px) !important;
  }
  .card-body {
    &:not(.drivers_tab_body_table):not(.locations_tab_body_table) {
      padding-top: 130px;
      padding-bottom: 20px;
    }
    &.drivers_tab_body_table {
      padding-top: 88.3px;
    }
    &.locations_tab_body_table {
      padding-top: 83px;
    }
  }
  .card-footer {
    border-bottom-left-radius: 23.8px;
    border-bottom-right-radius: 23.8px;
    background-color: rgba(234, 234, 234, 0.5) !important;
  }
  .loads_tabs-info,
  .expenses_tabs-info,
  .history_tabs-info,
  .drivers_tabs-info,
  .files_tabs-info {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 23px 23px 23px 0;
    background-color: #392b4c !important;
    padding: 5px 25px 3px 25px;
    width: fit-content;
    font-family: Barlow-Bold;
    font-size: 18px !important;
    font-weight: normal !important;
    color: #fff;
  }
  label {
    font-family: Barlow;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.37;
    letter-spacing: normal;
    text-align: left;
    color: #392b4c;
    padding-bottom: 8.9px;
    border-bottom: 0.3px solid #392b4c;
    margin-bottom: 24.9px;
    img {
      margin-left: 6.9px;
      margin-right: 9px;
      width: 23px;
      aspect-ratio: 1 / 1;
    }
  }
}

.files_tabs-info {
  width: auto !important;
  white-space: nowrap;
  font-family: Barlow-Bold;
  font-size: 30px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.2 !important;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  &.full-width {
    width: 100% !important;
    border-top-right-radius: 20.7px;
  }
}
.files-tabs-info-pt {
  padding-top: 87px !important;
}
.files_first-table-card {
  margin-bottom: 21.3px !important;
  .hr_files-table {
    margin: 9px 80px 44px 80px;
    width: auto;
  }
}

.load_tab-body {
  .broker_add-wrapper {
    width: 565px;
    height: 40px;
    display: flex !important;
  }
  .form-group {
    display: flex;
    vertical-align: middle;
    justify-content: space-around;
    button {
      width: 162.5px;
      height: 30px;
      border-radius: 15px;
      background-color: #4eb759;
      margin: auto 0 0 auto !important;
      font-family: Barlow;
      font-size: 18.8px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
    }
  }
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  .css-yk16xz-control > div:nth-child(2) {
    width: 40px;
    height: 38px;
    border-radius: 32%;
    position: relative !important;
    svg {
      display: none;
    }
    &::after {
      content: "";
      position: absolute;
      background-image: url("/assets/icons/select_arrow.png");
      width: 20px !important;
      height: 13px !important;
      background-position: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .css-tlfecz-indicatorContainer,
  .css-1gtu0rj-indicatorContainer {
    padding-top: 0;
    width: 7.5px;
  }
  .css-4ljt47-MenuList {
    background: #fff;
  }
  .css-yk16xz-control,
  .css-2b097c-container {
    width: 376.5px;
    height: 36px !important;
    border-radius: 16.1px;
    background-color: white;
    font-family: Barlow;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #392b4c !important;
    border: 0 !important;
    outline: 0 !important;
  }
  .css-g1d714-ValueContainer {
    padding: 0 14px;
  }
  .css-1wa3eu0-placeholder,
  .css-1uccc91-singleValue {
    color: #392b4c !important;
    outline: 0 !important;
    border: 0 !important;
  }
  .css-1pahdxg-control {
    background-color: white;
    border: 0;
    height: 36px !important;
    border-radius: 20px;
  }
  .css-26l3qy-menu {
    top: 45px !important;
    background-color: white;
    opacity: 1 !important;
  }
  input,
  select,
  textarea,
  input:focus,
  select:focus,
  textarea:focus {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    width: 565px;
    height: 36px !important;
    color: #392b4c !important;
    font-family: Barlow;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    border-radius: 16.1px;
    background-color: white;
    &:not(.validation_require)::placeholder {
      color: #392b4c !important;
      font-family: Barlow;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      opacity: 0.7;
    }
  }
  label {
    width: 302px;
    margin-right: 42.5px !important;
    margin-left: 73px !important;
  }
}
.expenses_left_side {
  width: 680px !important;
  margin-left: 25px;
  margin-top: -68px;
}

.expenses_right_side {
  width: 786px !important;
  margin-right: 31.5px !important;
  margin-left: 23.5px;
}

.expences_tab-body {
  .padding-removed {
    padding-left: 0 !important;
  }
  padding-left: 35px !important;
  padding-right: 35px !important;
  .broker_add-wrapper {
    width: 565px;
    height: 40px;
    display: flex !important;
  }
  .form-group {
    display: flex;
    vertical-align: middle;
    justify-content: space-around;
    button {
      width: 162.5px;
      height: 30px;
      border-radius: 15px;
      background-color: #4eb759;
      margin: auto 0 0 auto !important;
      font-family: Barlow;
      font-size: 18.8px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
    }
  }
  input,
  select,
  textarea,
  input:focus,
  select:focus,
  textarea:focus {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-left: 0px !important;
    margin-right: auto;
    width: 100%;
    height: 36px !important;
    color: #392b4c !important;
    font-family: Barlow;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    border-radius: 16.1px;
    background-color: white;
    &:not(.validation_require)::placeholder {
      color: #392b4c !important;
      font-family: Barlow;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      opacity: 0.7;
    }
  }
  label {
    width: 190.5px;
    margin-right: auto !important;
    margin-left: 37px !important;
  }
}

.tab-wrapper_with_right_padding {
  padding-right: 93px;
}

.expenses_right_side {
  height: fit-content;
  .card-body {
    padding-top: 85px !important;
    .btn-group {
      @include edit-delete-btns();
    }
  }
}

.map_tab-pane {
  width: 100% !important;
}

.drivers_tab-pane {
  width: 100% !important;
  .card-footer {
    border-top: 0;
    border-bottom-left-radius: 23.8px;
    border-bottom-right-radius: 23.8px;
    background-color: rgba(234, 234, 234, 0.5) !important;
  }
  .drivers_tab-body {
    .padding-removed {
      padding-left: 0 !important;
    }
    padding-left: 52px !important;
    padding-right: 52px !important;
    .broker_add-wrapper {
      width: 565px;
      height: 40px;
      display: flex !important;
    }
    .form-group {
      display: flex;
      vertical-align: middle;
      justify-content: center;
      button:not(.react_select_data_remove_button) {
        width: 162.5px;
        height: 30px;
        border-radius: 15px;
        background-color: #4eb759;
        margin: auto 0 0 auto !important;
        font-family: Barlow;
        font-size: 18.8px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
      }
    }
    input,
    select,
    textarea,
    input:focus,
    select:focus,
    textarea:focus {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      margin-left: auto !important;
      margin-right: 0;
      width: 100%;
      height: 36px !important;
      color: #392b4c !important;
      font-family: Barlow;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      border-radius: 16.1px;
      background-color: white;
      &:not(.validation_require)::placeholder {
        color: #392b4c !important;
        font-family: Barlow;
        font-size: 30px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: left;
        opacity: 0.7;
      }
    }
    label {
      width: 190.5px;
      margin-right: auto;
      margin-left: 0;
    }
  }
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  .css-yk16xz-control > div:nth-child(2) {
    width: 49px;
    height: 36px;
    border-radius: 35%;
    position: relative !important;
    svg {
      display: none;
    }
    &::after {
      content: "";
      position: absolute;
      background-image: url("/assets/icons/select_arrow.png");
      width: 26px !important;
      height: 13px !important;
      top: calc(50% - 7.5px) !important;
      left: calc(50% - 13px) !important;
    }
  }
  .css-tlfecz-indicatorContainer,
  .css-1gtu0rj-indicatorContainer {
    padding-top: 0;
    width: 7.5px;
  }
  .css-4ljt47-MenuList {
    background: #fff;
  }
  .css-yk16xz-control,
  .css-2b097c-container {
    width: 100%;
    height: 36px !important;
    border-radius: 16.1px;
    background-color: white;
    font-family: Barlow;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #4a60da !important;
    border: 0 !important;
    outline: 0 !important;
  }
  .css-g1d714-ValueContainer {
    padding: 0 14px;
  }
  .css-1wa3eu0-placeholder,
  .css-1uccc91-singleValue {
    color: #392b4c !important;
    outline: 0 !important;
    border: 0 !important;
  }
  .css-1pahdxg-control {
    background-color: white;
    border: 0;
    height: 36px !important;
    border-radius: 20px;
  }
  .css-26l3qy-menu {
    top: 45px !important;
    background-color: white;
    opacity: 1 !important;
  }
  .btn-group {
    &:nth-child(1) button {
      width: 85px !important;
      height: 36px;
      padding: 6px 35px 4.1px 1.9px !important;
      font-family: Barlow;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.8;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
      z-index: 0;
    }
    & > .btn:not(:first-child) {
      width: 65px !important;
      height: 36px;
      margin-left: -36px !important;
      padding: 6px 0px 4.1px 0px !important;
      padding-top: 6px !important;
      padding-right: 0px !important;
      padding-bottom: 4.1px !important;
      padding-left: 0px !important;
      border-radius: 22px;
      background-color: #db554d;
      font-family: Barlow;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.8;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
    }
  }
}

.test {
  border-radius: 21.9px;
  // box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.41);
  background-color: #fff;
  padding: 0 !important;
  footer {
    border-bottom-left-radius: 23.8px !important;
    border-bottom-right-radius: 23.8px !important;
    padding: 0 0 20px 26px !important;
    border-top: 0 !important;
  }
  .submit_button-edit {
    width: 121px;
    height: 35px;
    padding-top: 0 !important;
    padding-bottom: 6px !important;
    border-radius: 6.5px !important;
    background-color: #4a60da !important;
    font-family: Barlow;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    margin-right: 22.5px !important;
    img {
      width: 20px !important;
      margin-right: 5px !important;
    }
  }
  .reset_button-edit {
    width: 121px;
    height: 35px;
    padding-top: 0 !important;
    padding-bottom: 6px !important;
    border-radius: 6.5px !important;
    background-color: #b13573 !important;
    font-family: Barlow;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    img {
      width: 20px !important;
      margin-right: 5px !important;
    }
  }
  // .row_margin-right-0 {
  //   margin-right: 0 !important;
  //   margin-top: -10px;
  //   margin-left: -30px;
  //   padding-top: 10px !important;
  //   box-shadow: 0px 4px 0px 2px rgba(0, 0, 0, 0.41);
  //   border-top: 0;
  //   background-color: #fff;
  // }
  .drivers_edit_row-wrapper {
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-left: 28px !important;
    display: flex;
    width: 100% !important;
    margin-bottom: 31px;
  }
  // form {
  //   width: 770px !important;
  // }
  form {
    height: calc(100% - 20px);
  }
  form:nth-of-type(2) {
    margin-right: 30px !important;
    margin-left: auto !important;
  }
  form .card {
    border-radius: 21.6px !important;
  }
  .info_header {
    position: absolute;
    top: -1px;
    left: -1px;
    border-radius: 23px 23px 23px 0;
    background-color: #392b4c;
    padding-top: 3.3px;
    min-height: 33px;
    font-family: Barlow-Bold;
    font-size: 25px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
  .card-body {
    margin-top: 87px;
    padding-left: 30.6px;
    padding-right: 43.1px;
    padding-top: 0 !important;
    .css-yk16xz-control,
    .css-2b097c-container {
      width: 360px;
      height: 36px !important;
      border-radius: 16.1px;
      background-color: white;
      font-family: Barlow;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: #4a60da !important;
      border: 0 !important;
      outline: 0 !important;
    }
    .form-group:not(.chat_multi_select) .css-2b097c-container {
      margin-right: 18px !important;
      margin-left: auto !important;
    }
    .css-g1d714-ValueContainer {
      padding: 0 14px;
    }
    .css-1wa3eu0-placeholder,
    .css-1uccc91-singleValue {
      color: #392b4c !important;
      outline: 0 !important;
      border: 0 !important;
    }
    .css-1pahdxg-control {
      background-color: white;
      border: 0;
      height: 36px !important;
      border-radius: 20px;
    }
    .form-group:last-of-type {
      margin-bottom: 0 !important;
    }
    .form-group:not(.chat_multi_select) {
      display: flex;
      justify-content: space-between;
      vertical-align: middle;
      padding-left: 15px;
      &:nth-child(2) {
        margin-bottom: 20px !important;
      }
      img {
        margin-left: 7px !important;
        margin-right: 9px !important;
      }
      button {
        width: 91.9px;
        height: 30px;
        border-radius: 8.9px;
        background-color: #4eb759;
        margin: auto 0 13px !important;
        font-family: Barlow;
        font-size: 18.8px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
      }
      input,
      select,
      textarea,
      input:focus,
      select:focus,
      textarea:focus {
        // transform: translate(0, -11px) !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        margin-left: 22px !important;
        width: 465px;
        height: 36px !important;
        color: #392b4c !important;
        font-family: Barlow;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: left;
        border-radius: 16.1px;
        background-color: white;
        &:not(.validation_require)::placeholder {
          color: #392b4c !important;
          font-family: Barlow;
          font-size: 30px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: normal;
          text-align: left;
          opacity: 0.7;
        }
      }
    }
    label {
      width: 183.6px;
      min-height: 34px;
      padding-bottom: 6px;
      font-family: MyriadConceptRoman;
      vertical-align: middle;
      align-items: center;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.8;
      letter-spacing: normal;
      text-align: left;
      color: #392b4c;
      border-bottom: 0.3px solid #392b4c;
    }
  }
}

.load_tab-body .full_width-select-load-tab {
  width: 565px;
  .css-yk16xz-control,
  .css-1pahdxg-control {
    width: 565px !important;
  }
  .css-26l3qy-menu {
    background-color: #afafaf;
  }
}

.tab-content table tbody tr td {
  vertical-align: middle;
}

.edit_load_custom-map-wrapper,
.custom_map_wrapper {
  padding: 0 !important;
  div {
    border-top: 0 !important;
  }
  & div:nth-of-type(1):nth-child(1) {
    border-radius: 20.4px;
  }
  div .gmnoprint:nth-of-type(1) {
    margin: 0 !important;
    display: flex;
    white-space: nowrap !important;
    div.gm-style-mtc button[aria-checked="true"],
    div.gm-style-mtc button[aria-expanded="false"],
    div.gm-style-mtc button:active {
      background-color: #4a60da !important;
    }
    div.gm-style-mtc button[aria-checked="false"] {
      background-color: #392b4c !important;
    }
    div.gm-style-mtc:nth-of-type(1) button {
      border-radius: 24.5px;
      z-index: 0 !important;
      padding: 3px 75.3px 0px 14.3px !important;
      font-family: Barlow !important;
      font-size: 15px !important;
      font-weight: normal !important;
      font-stretch: normal !important;
      font-style: normal !important;
      line-height: 1.8 !important;
      letter-spacing: normal !important;
      text-align: center !important;
      color: #fff !important;
      &[aria-expanded="false"],
      &:active,
      &:hover,
      &:focus {
        z-index: 0 !important;
      }
    }
    div.gm-style-mtc:nth-of-type(2) button {
      z-index: 9999 !important;
      border-radius: 24.6px !important;
      padding: 3px !important;
      margin-left: -60px !important;
      font-family: Barlow !important;
      font-size: 15px !important;
      font-weight: normal !important;
      font-stretch: normal !important;
      font-style: normal !important;
      line-height: 1.8 !important;
      letter-spacing: normal !important;
      text-align: center !important;
      color: #fff !important;
    }
  }
  .gm-bundled-control {
    bottom: 115px !important;
    button[aria-label="Zoom out"] {
      border-radius: 9.3px;
      margin-top: 0 !important;
      border-top: 1px solid white !important;
    }
  }
  .gm-bundled-control {
    bottom: 115px !important;
    button[aria-label="Zoom in"] {
      border-radius: 9.3px;
    }
  }
  .gm-bundled-control .gmnoprint {
    left: -22.5px !important;
    top: -18px !important;
    div:nth-of-type(1) {
      border-radius: 9.3px !important;
      background-color: #4a60da !important;
      button:nth-of-type(1) {
        border-bottom: 1px solid white !important;
      }
      button img {
        filter: brightness(0) invert(1) !important;
      }
    }
  }
  .gm-control-active {
    margin-right: 20px !important;
    background-color: #4a60da !important;
    img {
      filter: brightness(0) invert(1) !important;
    }
  }
}

.custom_map_wrapper {
  margin-bottom: 0 !important;
  .custom_map_padding {
    padding: 0.5rem !important;
    .mb-4 {
      margin-bottom: 4px !important;
    }
  }

  .popover__content {
    min-width: 275px;
    background-color: #6587fd;
    color: #fff;
    border-radius: 5px;

    .popover__message span {
      color: #e0e0e0;
    }
  }

  .popover__content.info {
    padding: 15px 15px 10px 15px;
    // transform: translate(-43%, -14%) !important;

    // &:hover {
    //   transform: translate(-43%, -14%) !important;
    // }

    .weather-head {
      display: flex;
      justify-content: space-between;

      .driver-details {
        flex-basis: 50%;
      }

      .popover__message {
        display: flex;
        align-items: center;

        .head-icon {
          margin-right: 5px;
          min-width: 20px;
          max-width: 20px;
          height: 20px;
          background: #fff;
          color: #6587fd;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            display: block;
            width: 100%;
            height: auto;
          }
        }
      }

      .weather-today {
        position: relative;
        text-align: right;
        flex-basis: 50%;

        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }
    }

    .weather-foot {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;

      .weather-future {
        flex-basis: 23%;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 10px;
        text-align: center;
        padding: 10px 0;

        img {
          display: block;
          width: 80%;
          margin: 0 auto 10px auto;
          height: auto;
          border-radius: 10px;
        }
      }

      .weekday {
        color: #abd3f1;
      }
    }
  }
}

.border-removed {
  border: 0 !important;
  &.map_height {
    max-height: 100% !important;
  }
}

.row_header-driver-edit {
  width: 100%;
  margin: 0 !important;
  .col-12 {
    padding: 0 !important;
    border-radius: 21.9px;
    header {
      border-top-left-radius: 21.9px;
      border-top-right-radius: 21.9px;
    }
  }
}

.content_driver-edit-tab .active.show {
  display: flex !important;
}

.ul_wrapper {
  height: 50px;
  .ul_full_width {
    width: calc(100% - 30px);
    display: flex;
    justify-content: space-evenly;
    position: absolute;
    top: 50px;
    left: 14px;
    right: 14px;
    background-color: #392b4c !important;
    font-family: Barlow;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    border-top-left-radius: 21.9px;
    border-top-right-radius: 21.9px;
    li {
      width: 50%;
      text-align: center;
      a:not(.active) {
        color: white !important;
      }
      &:nth-of-type(1) a.active,
      &:nth-of-type(1) a:hover {
        border-top-left-radius: 21.9px !important;
      }
      &:nth-of-type(2) a.active,
      &:nth-of-type(2) a:hover {
        border-top-right-radius: 21.9px !important;
      }
    }
  }
}

.header_absolute-wrapper {
  position: absolute;
  top: 72px;
  width: 100%;
  .col-6:nth-of-type(2) {
    display: flex;
    justify-content: space-around;
    vertical-align: middle;
  }
}

.generate_invoice-button {
  background-color: #4eb759;
  font-family: Barlow;
  font-size: 16px;
  border-radius: 22px;
  padding: 0.25rem 2rem;
}

.drivers_tab-pane {
  form .card {
    margin-top: -64px;
  }
}

.locations_tab_body_table {
  .btn-group {
    @include edit-delete-btns();
  }
}
table td {
  white-space: nowrap;

  table thead {
    font-family: Barlow;
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #392b4c;
  }
  table tbody {
    font-family: Barlow;
    font-size: 35px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #392b4c;
  }
}

//changes design

.edit_add_data-wrapper .card-body.load_tab-body {
  padding-top: 90px;
  .form-group {
    margin-bottom: 8px !important;
  }
}
.edit_add_data-wrapper footer.load_tab_footer {
  padding-bottom: 10px !important;
}

.margin-fix-load-tab-body {
  margin-top: 0;
  .load_tab-body {
    padding-top: 80px !important;
  }
}

.height-auto {
  height: auto !important;
}

.driver_edit_body {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.form_group-select {
  select > option {
    width: 500px;
  }
}
.css-9gakcf-option:not(:hover) {
  color: #4a60da !important;
  background-color: transparent !important;
}
.css-1hb7zxy-IndicatorsContainer {
  filter: brightness(0) saturate(100%);
}

.edit_data_bordered-wrapper:not(.load_driver_edit_wrapper):not(.load_expenses_edit_wrapper),
.edit_driver_wrapper {
  margin: 0 !important;
  border-top-left-radius: 21.9px;
  border-top-right-radius: 21.9px;
  box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.41);
  background-color: #fff;
  height: calc(100vh - 58px);
  &:not(.height-auto) {
    // height: calc(100vh - 150px);
  }
  .form-group {
    label {
      font-family: Barlow;
      font-size: 14px;
      width: 90%;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.37;
      letter-spacing: normal;
      text-align: left;
      color: #392b4c;
      padding-bottom: 4.9px;
      border-bottom: 0.3px solid #392b4c;
      margin-bottom: 24.9px;
      img {
        margin-right: 7px;
      }
    }
  }
  form .card {
    border-radius: 21.6px !important;
  }
  footer {
    border-top: 0;
    border-radius: 21.6px !important;
    padding: 0 0 34.5px 20px !important;
  }
  .submit_button-edit {
    width: 121px;
    height: 35px;
    padding-top: 0 !important;
    padding-bottom: 6px !important;
    border-radius: 6.5px !important;
    background-color: #4a60da !important;
    font-family: Barlow;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    margin-right: 22.5px !important;
    img {
      width: 20px !important;
      margin-right: 5px !important;
    }
  }
  .reset_button-edit {
    width: 121px;
    height: 35px;
    padding-top: 0 !important;
    padding-bottom: 6px !important;
    border-radius: 6.5px !important;
    background-color: #b13573 !important;
    font-family: Barlow;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    img {
      width: 20px !important;
      margin-right: 5px !important;
    }
  }
  &:not(.tabs_exist) .card-body {
    padding-top: 87px !important;
  }
  &.tabs_exist .card-body {
    padding-top: 55px !important;
  }
  .edit_data-header_info,
  .info_header {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 23px 23px 23px 0;
    background-color: #392b4c !important;
    padding-top: 3.3px;
    min-height: 33px;
    font-family: Barlow-Bold;
    font-size: 25px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
  .css-yk16xz-control,
  .css-2b097c-container {
    width: 100%;
    height: 36px !important;
    border-radius: 16.1px;
    background-color: white;
    font-family: Barlow;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #392b4c;
    border: 1px solid #d9dce0 !important;
    outline: 0 !important;
  }
  .css-1pahdxg-control {
    background-color: white;
    border: 0;
    height: 36px !important;
    border-radius: 20px;
  }
  .css-26l3qy-menu {
    top: 33px !important;
    background-color: white;
    opacity: 1 !important;
  }
  .css-4ljt47-MenuList {
    background: #fff;
  }
  .css-yk16xz-control > div:nth-child(2) {
    width: 49px;
    height: 36px;
    border-radius: 35%;
    position: relative !important;
    filter: brightness(0) saturate(100%);
    svg {
      display: none;
    }
    &::after {
      content: "";
      position: absolute;
      background-image: url("/assets/icons/select_arrow.png");
      width: 26px !important;
      height: 13px !important;
      top: calc(50% - 7.5px) !important;
      left: calc(50% - 13px) !important;
    }
  }
  &:not(.one_more_fields) input,
  &:not(.one_more_fields) select,
  &:not(.one_more_fields) textarea,
  &:not(.one_more_fields) input:focus,
  &:not(.one_more_fields) select:focus,
  &:not(.one_more_fields) textarea:focus {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    width: 100%;
    height: 36px !important;
    font-family: Barlow;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;

    border-radius: 16.1px;
    background-color: white;
    &:not([name='load_status_id']) {
    color: #392b4c !important;
    }
    &:not(.validation_require)::placeholder {
      color: #392b4c !important;
      font-family: Barlow;
      font-size: 30px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: #4a60da;
      opacity: 0.7;
    }
  }
  &:not(.one_more_fields) textarea:not(:empty),
  &:not(.one_more_fields) textarea:not(:empty):focus {
    padding-top: 7px !important;
  }
  .rotated_image {
    transform: rotate(270deg);
  }
}

.info_header.header_border {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 23px 23px 23px 0;
  background-color: #392b4c !important;
  padding-top: 3.3px;
  min-height: 33px;
  font-family: Barlow-Bold;
  font-size: 25px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  header {
    border-top-right-radius: 0;
    border-top-left-radius: 21.6px;
  }
}

.edit_data_bordered-wrapper .card-header {
  border-bottom: 0 !important;
}
.edit_data_bordered-wrapper form .card.card_none-right-border {
  border-top-right-radius: 0 !important;
}

table.mt-20 {
  margin-top: 20px !important;
}

/*--------------------------Header notifications-----------------------------*/

.notifications-box {
  width: 500px;
  max-width: 500px;
  max-height: 500px;
  overflow-y: auto;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 15px;
  transform: translate(-435px, 65px);
  font-family: Barlow;
  font-size: 16px;

  .dropdown-item {
    &:active {
      background-color: transparent;
    }
    .message {
      width: 100%;
    }
  }

  .view-all {
    width: 100%;
    padding: 0.5rem 1.25rem;
    cursor: pointer;
    color: #4f5d73;
    font-weight: bold;
  }

  .dropdown-header.bg-light {
    font-size: 14px;
    color: #698aff;
    strong {
      font-weight: normal;
    }
  }
  .message {
    small {
      font-size: 14px;
    }
    .small.text-muted.text-truncate {
      font-size: 14px;
      color: #281f3f !important;
    }

    .c-avatar-status.bg-info {
      background-color: #2eb85c !important;
      border: none;
      width: 11px;
      height: 11px;
      bottom: -5px;
      right: -2px;
    }
  }
  .c-switch-lg {
    width: 68px;
  }
  .mark-as-read {
    cursor: pointer;
    font-weight: bold;
  }
}

.notification-sender.c-avatar {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border: 3px solid #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  font-size: 25px;
  background: #a26b5f;
  color: #fff;
}

.notification-modal {
  .close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 25px;
    display: flex;
    cursor: pointer;
  }
  .notif-link {
    background-color: #698aff;
    color: #fff;
    transition: all 0.2s ease;
    padding: 10px 15px;
    font-size: 14px;
    border-radius: 10px;
    box-shadow: 2px 2px 1px 0 rgba(0, 0, 0, 0.25);
    width: fit-content;
    margin-bottom: 1rem;
    margin-left: auto;
    cursor: pointer;

    &:hover {
      background-color: darken($color: #698aff, $amount: 5);
    }
  }
}

.c-switch-label .c-switch-input:checked ~ .c-switch-slider::after,
html:not([dir="rtl"]) .c-switch-label .c-switch-slider::after {
  font-size: 14px;
}
.css-tlfecz-indicatorContainer,
.css-1okebmr-indicatorSeparator {
  display: none !important;
}

.edit_driver_wrapper .multi_select .css-yk16xz-control,
.edit_driver_wrapper .multi_select .css-2b097c-container,
.edit_driver_wrapper .multi_select .css-1pahdxg-control {
  height: auto !important;
}
.edit_driver_wrapper .multi_select .css-1hb7zxy-IndicatorsContainer {
  margin: auto !important;
}

.load_edit_wrapper .active.fade.show {
  width: 100% !important;
}

.fixed_table.pagination_none.mr-none {
  margin-right: 0;
}

.edit_data_bordered-wrapper.bg-none-opacity {
  background: transparent !important;
  box-shadow: none !important;
}
.header_absolute-wrapper .flex {
  display: flex;
}
.pl-none {
  padding-left: 0;
}

.p-0 {
  padding: 0 !important;
}

.files_tabs-info .status_info {
  display: flex;
  span {
    padding: 1px 1.5px 0px;
    margin-left: 3px;
    font-size: 14px;
    display: flex;
    vertical-align: middle;
    align-items: center;
  }
}

.c-sidebar-nav-link.c-active {
  background-color: #4a60da !important;
}

.validation_required .css-yk16xz-control {
  border: 1px solid red !important;
}

.edit_driver_wrapper div:not(.validation_required) .css-yk16xz-control,
.validation_require .css-yk16xz-control {
  border: 0.5px solid #d8dbe0 !important;
}
.edit_driver_wrapper
  div.validation_required:not(:focus)
  .css-yk16xz-control:not(:focus),
.load_edit_wrapper .validation_require:not(:focus) {
  border: 1px solid red !important;
}
.load_edit_wrapper .validation_require:not(:valid) {
  height: 40px !important;
}
.css-1pahdxg-control:hover,
.css-1pahdxg-control .css-1pahdxg-control:active {
  border-color: rgb(216, 219, 224) !important;
}
.m-0 {
  margin: 0 !important;
}

.expenses_left_side,
.expenses_right_side {
  width: 100% !important;
}
.load_number_header-wrapper {
  display: flex;
}
.load_number_header {
  background-color: #392b4c;
  margin: auto 0;
  color: white;
  padding: 6px 16px;
  font-family: Barlow-Bold;
  font-size: 16px !important;
  border-radius: 23px 0 23px 0;
}
.load_number_header.reference_number {
  margin-left: 20px;
  border-radius: 0;
}

.expenses_left_side label {
  margin-left: 0 !important;
}
.c-active {
  position: relative;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.delete_button-absolute {
  margin-left: -29px !important;
}

.filter_button {
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 100px;
  background-color: blue;
}
.history_table-bg .fixed_table tbody,
.history_table-bg .fixed_table thead th {
  text-align: left !important;
}

.border-radius_wrapper.load_edit_wrapper.height-auto {
  min-height: calc(100vh - 65px) !important;
  margin-bottom: 0 !important;
}

html:not([dir="rtl"])
  .btn-group
  > .btn:not(:last-child):not(.dropdown-toggle):active,
html:not([dir="rtl"])
  .btn-group
  > .btn:not(:last-child):not(.dropdown-toggle):focus {
  box-shadow: none !important;
}

.location_add_form {
  margin-top: 10px !important;
}

.notes_load_edit:not(pre) {
  min-height: 200px;
}

.edit_button_expenses {
  width: 60px !important;
  padding-left: 2px !important;
}

.link_has_next_load {
  color: orange;
}

.driver_loads_table_span {
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  margin: auto;
  a {
    margin: auto 5px;
  }
}

.filter_toggle_statement {
  position: absolute;
  width: 130px;
  left: 20px;
}

.filter_toggled_wrapper {
  height: calc(100vh - 70px);
  background-color: white;
  overflow: inherit;
  padding-top: 100px;
  z-index: 99;
  label {
    margin: 10px auto;
  }
  label:after {
    content: "";
    width: 80%;
    position: absolute;
    bottom: 5px;
    left: 10%;
    background-color: khaki;
    height: 0.4px;
  }
  .react-datepicker__month {
    background-color: white;
  }
  input[type="text"] {
    text-align: center;
    font-family: MyriadPro;
    font-weight: normal;
    font-size: 14px;
    padding: 8px;
    border: 0;
    &:hover {
      border-width: 1px;
    }
    &:nth-of-type() {
      margin-left: 2px;
      margin-right: 2px;
    }
  }

  .react-datepicker__input-container {
    input {
      font-size: 13px;
    }
  }

  .filter-collumns_wrapper .col-lg-12 {
    display: flex !important;
    justify-content: space-around !important;
    text-align: center;
    svg {
      margin: auto 5px;
    }
    label {
      font-size: 14px;
    }
  }
  .edit_data-header_info {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 23px 23px 23px 0;
    background-color: #392b4c !important;
    padding-top: 3.3px;
    min-height: 33px;
    font-family: Barlow-Bold;
    font-size: 25px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
}

.pl-3 {
  padding-left: 3px;
}

.batch_filter_opened .c-datatable-items-per-page label {
  display: none;
}

.brokers_page .css-yk16xz-control,
.brokers_page .css-1pahdxg {
  border: 1px solid rgb(216, 219, 224) !important;
}

.diary_page {
  textarea {
    min-height: 100%;
  }
  .circle-2 svg {
    margin-left: 13px !important;
  }
  // svg.mfe-2 {
  //  margin-right: 0 !important;
  // }
}

.red_load_id a {
  color: red;
}

.file_upload_button-wrapper {
  display: flex;
  button {
    margin-right: 0;
    margin-left: auto;
    background-color: #4a60da;
    border: 1px solid #4a60da;
    border-radius: 20px;
    width: 245px;
    height: 37px;
    color: white;
    img {
      width: 20px;
      margin-right: 10px;
    }
  }
}

.file_upload_modal {
  margin: 0 !important;
}

.file_upload_main-div .modal-content {
  border-radius: 21.9px;
  .mt-60 {
    margin-top: 60px;
    margin-bottom: 30px;
  }
  .card-body {
    padding: 0 !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .form-group {
      display: flex;
      button {
        width: 100%;
        font-size: 14px;
      }
      input,
      button,
      select {
        margin: 15px auto;
        height: 36px !important;
        border-radius: 21.9px;
      }
      input {
        color: transparent !important;
        position: relative;
        width: 90%;
        padding: 0 !important;
        border: 0 !important;
        outline: 0 !important;
        background-color: green;
        &::-webkit-file-upload-button {
          visibility: hidden;
        }
        &:after {
          content: "Select File";
          position: absolute;
          top: calc(50% - 9.5px);
          left: calc(50% - 33px);
          font-size: 14px;
          color: white;
        }
      }
    }
  }
}
.justify-center {
  justify-content: center;
  padding: 15px 0 !important;
  margin: 0 0 15px 0 !important;
  border-top-left-radius: 21.9px;
  border-top-right-radius: 21.9px;
  transform: translate(1.25px, 0);
}

.close_modal {
  background-color: #281f3f;
  border: 0;
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 4px;
  right: 7px;
}

.upload_photo {
  filter: grayscale(1) invert(1);
}

.validation_require:not(:valid) {
  border: 1px solid red !important;
}

.validation_require::placeholder {
  color: grey !important;
  font-family: Barlow;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  opacity: 0.4;
}

.week_picker {
  width: 100%;
  text-align: center;
}

.batch_filter_form_group {
  align-items: center !important;
}

.mw-35px {
  min-width: 35px !important;
}
.selected_filter_field {
  background-color: rgb(74, 96, 218);
  color: white;
  padding: 8 0 !important;
  user-select: none;
}
.selected_filter_field.danger {
  background-color: #db554d;
}
.selected_filter_field.success {
  background-color: #2eb85c;
}

table.cursor-pointer tbody td {
  cursor: pointer;
}

.fixed_table .remove_fixed-height .table-responsive {
  height: auto !important;
}

.remove_filter_button {
  background-color: rgb(74, 96, 218);
  margin: 0 auto;
  width: 50% !important;
  font-size: 14px;
  color: white;
}

.css-1uccc91-singleValue {
  transform: translateY(-57%) !important;
}

.css-1wa3eu0-placeholder {
  padding-bottom: 8px;
}

textarea {
  min-height: 70px !important;
}

.edit_data_bordered-wrapper textarea {
  min-height: 90px !important;
}

.load_add_location-button {
  border-radius: 30px;
}

select:not([aria-label="changes number of visible items"]) {
  -webkit-appearance: none;
  position: relative;
  background: url("/assets/icons/select_default_arrow.png") no-repeat right !important;
  background-position: bottom 50% right 13px !important;
  color: white !important;
  border: 0.5px solid #d8dbe0 !important;
}

select:not([aria-label="changes number of visible items"]):not([class*="bg-"]):not(.text-white) {
  background-color: white !important;
  color: black !important;
}

select:not([aria-label="changes number of visible items"]).bg-info {
  background-color: #3399FF !important;
  color: white !important;
}

select:not([aria-label="changes number of visible items"]).bg-warning {
  background-color: #F9B115 !important;
  color: white !important;
}

select:not([aria-label="changes number of visible items"]).bg-danger {
  background-color: #E55353 !important;
  color: white !important;
}

select:not([aria-label="changes number of visible items"]).bg-secondary {
  background-color: #dddfe2 !important;
  color: black !important;
}

select:not([aria-label="changes number of visible items"]).bg-success {
  background-color: #2EB85C !important;
  color: white !important;
}

select:not([aria-label="changes number of visible items"]).bg-primary {
  background-color: #321FDB !important;
  color: white !important;
}

select:not([aria-label="changes number of visible items"]).bg-dark {
  background-color: #636F83 !important;
  color: white !important;
}

.locations_date-pl-8 input {
  padding-left: 8px !important;
}

.statement-mt-50 {
  margin-top: 50px !important;
  user-select: none;
}

.table-note-edit-input {
  min-width: 200px !important;
  width: 100% !important;
  height: 60px;
}

.button_switch-wrapper.isSold .c-switch-slider {
  min-width: 55px;
}

.button_switch-wrapper.isSold.t-65px {
  top: 65px;
}
.button_switch-wrapper.isSold.l-0 {
  left: 0;
}

.button_switch-wrapper.isSold {
  position: absolute;
  top: 26px;
  right: 10px !important;
  label {
    margin: 0 !important;
    padding: 0 !important;
    border: 0 !important;
  }
  .c-switch-label .c-switch-slider::after {
    left: -90px;
    top: 14px;
  }
  input:not(:checked) + .c-switch-slider {
    background-color: white !important;
  }
}

.pr-65 {
  padding-right: 65px !important;
  padding-bottom: 17px !important;
  padding-top: 17px !important;
}

.badge.ml-0 {
  margin-left: 0;
  border-radius: 15px;
}

.profile-buttons-wrapper {
  margin-top: auto;
  margin-bottom: 10px;
  justify-content: left;
  .first_button {
    width: 80px;
    height: 17px;
    padding: 0 46.5px 0 6.5px;
    border-radius: 5.2px;
    background-color: #f8f8f8;
    font-family: Roboto;
    font-size: 9.2px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #281f3f;
    &:focus {
      outline: 0 !important;
    }
  }
  .second_button {
    width: 42px;
    height: 17px;
    margin-left: -40.5px;
    padding: 1px 3.6px 2px 5.4px;
    border-radius: 5.2px;
    background-color: #281f3f;
    font-family: Roboto;
    font-size: 9.2px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #f8f8f8;
  }
}

.profile-buttons-wrapper .mini_notyf {
  display: inline-flex;
  width: 0;
  height: 0;
  list-style: none;
  background-color: #392b4c;
  .c-header-nav-link {
    padding: 0 !important;
  }
  svg {
    width: 23.9px;
    height: 21.3px;
  }
  .badge.badge-info.badge-pill {
    position: absolute;
    top: 0;
    bottom: 100%;
    height: auto;
    left: 0;
    background-color: transparent;
    p {
      margin-bottom: 0 !important;
      font-size: 7px;
      padding: 1px 1.5px !important;
      border: solid 0.5px #fff;
      border-radius: 50% !important;
      position: absolute;
      top: 0;
    }
  }
  .notifications-box {
    transform: translate(0px, 25px) !important;
  }
}

.custom-table_pagination {
  position: absolute;
  top: 13px;
  right: 15px;
  .pagination {
    height: 32px;
  }
  li[role="separator"] {
    display: none !important;
  }
}

.c-main .container-fluid {
  padding: 0 !important;
}

.c-header-nav .c-header-nav-link .badge,
.c-header-nav .c-header-nav-btn .badge {
  margin-top: -14px;
}

.table-mt-50 {
  margin-top: 50px !important;
}

.c-body.main_content_wrapper {
  transform: translate(0px, -5px);
}

.c-sidebar-nav-dropdown {
  transition: all 0s !important;
}

.table-mt-75 {
  margin-top: 75px;
}
.table-mt-40 {
  margin-top: 40px;
}

.fixed_table .table-mt-50 .table-responsive {
  height: calc(100vh - 132px) !important;
}

.fixed_table .table-mt-75 .table-responsive {
  height: calc(100vh - 157px) !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.bordered-card_edit-broker {
  border-radius: 24px;
  margin-top: 15px;
  margin-bottom: 15px;
  header {
    border-radius: 24px !important;
  }
}

.fixed_table .table-responsive tbody td a {
  color: #4a60da !important;
}

.fixed_table .table-responsive thead th select:not(.text-white) {
  color: black !important;
}
// ------------------------------------------- Login wrapper
.login-wrapper {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: Barlow;

  * {
    font-family: Barlow;
  }

  .container {
    position: relative;
    z-index: 2;
  }

  .card-group {
    border-radius: 15px;

    > div {
      border-radius: 15px;
      &:first-child {
        border: 2px solid #bcbcbc;
      }
    }

    .bg-primary {
      background-color: #6587fe !important;
      border-radius: 15px;
      position: relative;
      left: -15px;
    }
    .btn-primary {
      background-color: #6588ff;
      border: none;
      border-radius: 20px;

      &:hover {
        background-color: #4f76ff;
      }

      &:focus {
        background-color: #6588ff;
        box-shadow: none;
      }
    }
    .input-group {
      position: relative;
      .input-group-prepend {
        position: absolute;
        right: 0;
        z-index: 5;
        height: 100%;

        .input-group-text {
          background-color: #392b4c;
          border-radius: 10px;
          color: #fff;
        }
      }
    }

    input {
      border-radius: 10px !important;
      &:focus {
        box-shadow: none;
        border-color: #392b4c;
      }
    }
  }
}

.overlay-blur {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  background: rgba(0, 0, 0, 0.2);
}

.events_page {
  height: calc(100vh - 60px);
  margin-bottom: 0 !important;
  border-radius: 40px;
  padding-top: 10px;
  &.height-auto {
    height: auto !important;
    .badge {
      white-space: normal;
      a {
        color: currentColor;
      }
    }
  }
  .react-datepicker {
    height: 100%;
    .custom_days_wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      max-width: 100%;
      padding-top: 25px;
      position: relative;
      overflow: hidden;
      .badged_date-item {
        display: flex !important;
        text-align: center;
        margin: 2px 4px;
        padding: 5px;
        min-height: 18px;
        &:last-of-type {
          margin-top: 2px !important;
          margin-bottom: auto !important;
          width: fit-content;
        }
        &:hover {
          background-color: darkblue !important;
        }
      }
    }
    span {
      max-width: calc(100% - 8px) !important;
      margin: 0 auto;
      overflow: hidden;
    }
  }
  .react-datepicker {
    margin: 0 !important;
    width: 100%;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    button:nth-of-type(1) {
      margin-top: 2px;
      margin-left: 6px;
    }
    button:nth-of-type(2) {
      margin-top: 2px;
      margin-right: 6px;
      right: auto;
      left: 60px;
    }
    button {
      border-radius: 50%;
      span {
        overflow: visible !important;
        top: 5px;
        &.react-datepicker__navigation-icon::before {
          border-color: white !important;
        }
      }
      &:focus {
        outline: none !important;
        border: 0 !important;
      }
    }
    .react-datepicker__current-month {
      padding-bottom: 10px;
    }
    .react-datepicker__header,
    .react-datepicker__current-month {
      background-color: gray;
      border-top-right-radius: 25px;
      border-top-left-radius: 25px;
      color: black !important;
    }
    .react-datepicker__day-name,
    .react-datepicker__header {
      border: 1px solid white !important;
      &:first-of-type {
        border-left-width: 0 !important;
      }
      &:last-of-type {
        border-right-width: 0 !important;
      }
    }
  }
  .react-datepicker-popper,
  .react-datepicker__month-container,
  .react-datepicker__day-names {
    width: 100% !important;
    .react-datepicker__day-name,
    .react-datepicker__day {
      width: calc(100% / 7) !important;
      height: 100%;
      margin: 0 !important;
      padding: 0 0 5px 0 !important;
      color: white !important;
      .date_day {
        margin-top: 5px;
        margin-bottom: 15px;
        position: absolute;
        top: -5px;
        left: calc(50% - 2ch);
      }
    }
    .react-datepicker__day,
    .react-datepicker__day--selected {
      background: white;
      color: black !important;
      font-weight: 700;
      box-shadow: 2px 0 2px 1.8px gray;
      margin: 0 !important;
      display: flex !important;
      justify-content: center;
      text-align: center;
      flex-direction: column;
    }
    .react-datepicker__day:hover,
    .react-datepicker__day--selected:hover {
      transform: scale(1.2);
      background: linear-gradient(
        135deg,
        white,
        white 2%,
        #006dcc 2%,
        #006dcc 7%,
        #0088ff 7%,
        #4a60da 12%,
        white 12%,
        white
      ) !important;
      z-index: 1;
    }
  }
  input {
    border: 1px solid black !important;
    border-radius: 10px !important;
  }
}
.events_page .react-datepicker {
  height: calc(100vh - 105px) !important;
  .react-datepicker__month {
    height: calc(100vh - 190px) !important;
    display: flex !important;
    flex-direction: column;
  }
  .react-datepicker__week {
    height: 19.8% !important;
    display: flex;
    flex-direction: row;
  }
}
.event_add_button {
  position: absolute;
  z-index: 9999;
  font-size: 10px;
  width: 10%;
  left: calc(19% - 7.5%);
  top: 19px;
}

.add_data_table.remove_date {
  background-color: #000000 !important;
  width: auto;
  margin: 0 0 0 0;
  position: absolute;
  top: 40px;
  right: 100px;
  border: none;
  font-size: 12px;
}

.todo_list {
  margin: 5px 20px 5px auto;
  display: flex;
  flex-direction: column;
  .todo_list_header {
    justify-content: center;
    margin-top: 20px;
    h3 {
      font-weight: 700;
      text-align: left;
      margin: 15px auto 0px 25px;
    }
  }
  .main_wrapper {
    margin-top: 20px;
    min-height: calc(100vh - 180px);
    border: 1px solid #535a9e;
    border-radius: 30px;
    .event_item {
      margin: 10px;
      padding: 7.5px;
      border: 1px solid #e2e2e2;
      border-radius: 10px;
      &:first-of-type {
        margin-top: 20px !important;
      }
      &:hover {
        transform: scale(1.02) !important;
        transition: all 0.3s linear;
        .time_value::after {
          // opacity: 0;
          transition: all 0.5s ease;
        }
      }
      .time_value {
        // background: linear-gradient(rgba(0, 0, 0, 0) 10%, #4A60DA 40%), linear-gradient(90deg, rgba(0, 0, 0, 0) 10%, #4A60DA 25%);
        background-size: 3px 3px;
        // background-color: seashell;
        background-position: center 1em;
        font-size: 10px;
        display: inline-flex;
        padding: 7px 0px !important;
        height: 35px;
        margin: 0 !important;
        // color: white;
        color: black;
        position: relative;
        // &::after {
        //   content: '';
        //   width: 100%;
        //   top: 0;
        //   left: 0;
        //   border: 17.5px solid transparent;
        //   border-left: 17.5px solid white;
        //   position: absolute;
        // }
      }
      .item_note {
        min-height: calc(100% - 3px);
        display: flex;
        padding: 5px 0px 10px 4px;
        font-size: 14px;
        margin: 0;
        // width: calc(100% - 175px);
        position: relative;
        white-space: break-spaces;
        background: #ddf7e6;
        color: #000;
      }
    }
    button.delete_event_button {
      // width: 100%;
      margin-top: 5px;
      margin-left: auto;
      padding: 3px 10px;
      font-size: 10px;
    }
    .event_username {
      position: absolute;
      right: 5px;
      bottom: 0px;
      font-size: 10px;
    }
    .event_username p {
      margin: 0;
    }
  }
}

.second_row_edit_page_buttons {
  margin-top: 15px !important;
  display: flex;
  justify-content: center;
}
.second_row_edit_page_buttons button {
  margin: 0 10px;
  width: 30%;
}

.table_custom_multi_header tbody tr:not(.without_header) td {
  padding-top: 30px;
}
.table_custom_multi_header tbody tr:not(.without_header) {
  position: relative;
}
.table_custom_multi_header tbody tr[class]:not(.without_header)::after {
  content: "";
  // background-color: green;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.table_custom_multi_header tbody tr[class]:not(.without_header) td {
  position: inherit;
}
.table_custom_multi_header tbody tr[class]:not(.without_header) td::after {
  top: 0;
  position: absolute;
  left: 0;
  width: 100%;
  background-color: #b0c0d0;
  padding: 5px 0;
  content: "";
  display: flex;
  justify-content: center;
  height: 25px;
}
.table_custom_multi_header
  tbody
  tr[class]:not(.without_header)
  td:nth-of-type(3)::after {
  content: attr(datadispatcher);
}

.table_custom_multi_header
  tbody
  tr[class]:not(.without_header)
  td:nth-of-type(5)::after {
  content: attr(dataqc);
}
.table_custom_multi_header
  tbody
  tr[class]:not(.without_header)
  td:nth-of-type(6)::after {
  content: attr(datacodispatcher);
}
.table_custom_multi_header
  tbody
  tr[class]:not(.without_header)
  td:nth-of-type(8)::after {
  content: attr(datacoqc);
}

.item_note.danger a {
  color: white !important;
}

.item_note.success a,
.item_note.secondary a {
  color: black !important;
}
.item_note.danger {
  background: #ff9f90;
}
.item_note.secondary {
  background: silver;
}
.item_note.success {
  background: #87e1a5;
}

.react-datepicker__input-container input {
  padding-left: 8px;
}

.react-datepicker__input-container input:not(.b1-gray) {
  border: 0 !important;
}

.react-datepicker__input-container input.b1-gray {
  border: 1px solid #d8dbe0;
}

.header-notyf-title,
.header-notyf-message {
  color: black;
}
.header-close-notyf-button {
  filter: invert(100%);
}

.setDays_button_wrapper {
  margin: auto 25px 0 0;
  button {
    background-color: black;
    color: white;
    outline: none;
    border-radius: 50%;
    border: 0;
  }
  button:nth-of-type(1) {
    margin-right: 5px;
  }
}

.table_custom_multi_header tbody tr:nth-of-type(1) td:nth-of-type(2):before {
  content: attr(id);
}
.table_custom_multi_header tbody tr:nth-of-type(1) td:nth-of-type(5):before {
  content: attr(id);
}
// .table_custom_multi_header tbody tr:nth-of-type(1) td:nth-of-type(6):before {
//   content: "NIGHT MANAGER: ADAM";
// }
.table_custom_multi_header tbody tr:nth-of-type(1) td:nth-of-type(7):before {
  content: attr(id);
}

.table_custom_multi_header tbody tr:nth-of-type(1) td::before {
  background-color: #b0c0d0 !important;
  position: absolute;
  content: "";
  width: 100%;
  height: 60px;
  border-bottom: 1px solid white;
  border-top: 1px solid white;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: break-spaces;
  overflow: visible;
}
.table_custom_multi_header tbody tr:nth-of-type(1) td:after {
  top: 60px !important;
}

.table_custom_multi_header tbody tr:nth-of-type(1) td {
  padding-top: 90px;
}

.upload_driver_file-button:disabled {
  background-color: grey;
  border: 0;
}

.table-booked_by-dispatched_by_field:not(.more_locations) img {
  display: none;
}
.table-booked_by-dispatched_by_field.more_locations {
  position: relative;
}

.table-booked_by-dispatched_by_field.more_locations .more_locations_icon {
  width: 16px;
  height: 16px;
  margin-right: 3px;
  position: absolute;
  left: 2px;
  top: 16px;
}

.modal-content #file-multiple-input:focus {
  background-color: #008000;
  font-size: 0;
}

.table-booked_by-dispatched_by_field.more_locations span {
  padding-left: 20px;
}

.arrow-left-modal-image,
.arrow-right-modal-image {
  background-color: black;
  padding: 20px;
  cursor: pointer;
}

.modal-content .buttons_wrapper {
  position: absolute;
  width: 100%;
  display: flex;
  background: rgba(255, 255, 255, 0.2);
  user-select: none;
}
.arrow-right-modal-image {
  transform: rotate(180deg);
  margin-left: auto;
  margin-right: 0;
}

// .table_custom_multi_header tbody tr:nth-of-type(1) td {
//   padding-top: 100px;
// }

div[class*="load/"] {
  .modal-dialog,
  .fade {
    transition: none !important;
  }
  .modal-backdrop {
    position: fixed;
    top: -100px;
    height: 200vh;
    width: 100vw;
    left: -200px;
    transition: none;
  }
}

.c-sidebar {
  transition: none;
  &.responsive {
    transition: box-shadow 0.3s 0.15s, transform 0.3s, margin-left 0.3s,
      margin-right 0.3s, width 0.3s, z-index 0s ease 0.3s,
      -webkit-transform 0.3s;
  }
}

.slider-modal .modal-dialog {
  margin: auto;
  max-width: 90%;
}

.slider-modal .modal-dialog a img {
  width: 100%;
  height: auto;
  max-height: 100%;
}
.revised-load-switch {
  min-width: 55px;
  height: 30px;
  margin: 0 !important;
  padding: 0 !important;
  border: 0 !important;

  &.c-switch[class*="-3d"].c-switch-lg
    .c-switch-input:checked
    ~ .c-switch-slider::before {
    transform: translateX(23px);
  }
}

.fixed_table .table-striped ~ .loader_table {
  z-index: 99;
  background: white;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.css-1fhf3k1-control {
  height: 36px;
  border-radius: 16.1px !important;
  background: white !important;
  &::after {
    content: "";
    position: absolute;
    top: 6px;
    right: 13px;
    width: 24px;
    height: 24px;
    border-radius: 50px;
    border: 5px solid grey;
    border-top-color: black;
    -webkit-animation: loading 2s linear infinite;
    animation: loading 2s linear infinite;
  }
  .css-1wa3eu0-placeholder {
    font-size: 0;
    width: 100%;
    top: 25%;
    &::after {
      content: "Data is fetching...";
      width: 100%;
      position: absolute;
      left: 5px;
      top: 0px;
      font-size: 12px;
    }
  }
  input::placeholder {
    font-size: 0;
  }
  .css-1hb7zxy-IndicatorsContainer::after {
    display: none !important;
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.css-at12u2-loadingIndicator,
.css-1xh8qep-loadingIndicator {
  filter: none !important;
  color: white !important;
  position: absolute !important;
  top: -4px !important;
  right: -2px !important;
  width: 40px !important;
  height: 34px !important;
}
.css-2b097c-container .css-1pahdxg-control > div:nth-child(2) svg {
  display: none;
}

.css-2b097c-container .css-1pahdxg-control > div:nth-child(2):before {
  content: "";
  position: absolute;
  background-image: url("/assets/icons/select_arrow.png");
  width: 26px !important;
  height: 13px !important;
  top: calc(50% - 0.5px) !important;
  left: calc(100% - 35px) !important;
}
.has_more.css-2b097c-container .css-1pahdxg-control > div:nth-child(2):after {
  content: "";
  position: absolute;
  background-image: url("/assets/icons/select_arrow.png");
  width: 26px !important;
  height: 13px !important;
  top: calc(50% - 10.5px) !important;
  left: calc(100% - 35px) !important;
}

// .has_more.css-2b097c-container .css-1pahdxg-control >div:nth-child(2) div:after {
//   content: "";
//   position: absolute;
//   background-image: url("/assets/icons/select_arrow.png");
//   width: 26px !important;
//   height: 13px !important;
//   top: calc(50% - 0.5px) !important;
//   left: calc(50% - 13px) !important;
// }

.edit_data_bordered-wrapper:not(.load_driver_edit_wrapper):not(.load_expenses_edit_wrapper):not(.one_more_fields)
  textarea.notes_load_edit {
  padding-top: 2.5px !important;
}

.modal-dialog .upload-process {
  .card:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -30px;
    margin-left: -30px;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    border: 5px solid grey;
    border-top-color: black;
    animation: loading 2s linear infinite;
  }
  .bg-white.row {
    max-height: 0;
    visibility: hidden;
  }
  .card-body {
    visibility: hidden;
  }
}

.slider-modal + .modal-backdrop.fade.show,
.file_upload_main-div + .modal-backdrop.fade.show,
.modal_left-0 + .modal-backdrop.fade.show {
  height: calc(100% + 100px);
}

.upload_files_preloader-image {
  width: 100%;
}

.preview_upload_files-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% + 25px);
}

.upload_files-delete_button_wrapper {
  justify-content: right;
  padding-right: 0;
}

.react_select_data_remove_button {
  z-index: 99;
  position: absolute;
  top: 10px;
  border-radius: 50%;
  border: 0;
  width: 26px;
  height: 26px;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  right: 60px;
  margin: 0;
  font-weight: 700;
  font-size: 12px;
}
// .driver_widget_infos_wrapper {
//   display: none;
// }

// .table-shipper-consigne_field:hover .driver_widget_infos_wrapper {
//   display: block;
// }

.main {
  margin-top: 50px;
}
.weather-panel {
  background: #6587fd;
  background-size: cover;
  border-radius: 20px;
  box-shadow: 25px 25px 40px 0px rgba(0, 0, 0, 0.33);
  color: #fff;
  overflow: hidden;
  position: relative;
  padding: 10px;
  img {
    max-width: 100px;
  }
  small {
    color: inherit;
    font-size: 50%;
  }
  ul.forecast > li {
    border-top: 1px solid #fff;
  }
  span {
    height: auto;
  }
  .driver_location_info_icons {
    max-width: 30px;
  }
  .driver_location_info_icons_wrapper {
    display: flex;
  }
  .driver_info_address {
    margin-top: 25px;
  }
}

.driver_info_distance_part {
  flex-direction: column;
  display: flex;
  justify-content: center;
  img,
  h2 {
    margin: auto;
  }
}

.driver_info_modal_body {
  background: #5b64a9;
  .main {
    margin-top: 0;
  }
}

.drivers_info_show_toggle {
  padding: 0;
  margin-left: 5px;
  border: 0;
  outline: 0;
}

.drivers_info_show_toggle img {
  width: 25px;
}

.fixed_table .edit_note_styles_edit-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  label:nth-of-type(1) {
    border-top-left-radius: 40px !important;
    border-bottom-left-radius: 40px !important;
  }
  label:last-of-type {
    border-top-right-radius: 40px !important;
    border-bottom-right-radius: 40px !important;
  }
  label {
    padding-right: 4px;
    display: inline-block;
    padding: 2px 2px;
    font-family: sans-serif, Arial;
    font-size: 16px;
    border-radius: 0px;
    outline: 0;
    border: 0;
    background: #323333;
    width: 100%;
    display: flex;
    justify-content: center;
    &.color_radio_label {
      width: 100%;
      height: 18px;
      &:after {
        content: "";
        position: absolute;
        width: 10px;
        top: calc(50% - 9px);
        height: 10px;
        border-radius: 50%;
      }
      &.red:after {
        background: red;
      }
      &.black:after {
        background: black;
      }
      &.white:after {
        background: white;
      }
      &.green:after {
        background: green;
      }
      &.yellow:after {
        background: #ffff70;
      }
    }
    &:not(.color_radio_label) {
      color: white;
    }
    img {
      height: 14px;
    }
  }
  input {
    margin: 0 !important;
    opacity: 0;
    position: fixed;
    width: 0;
  }
  input[type="radio"]:checked + label {
    background-color: #03da97;
    border-color: #4c4;
    &.color_radio_label::before {
      background: #f2f2f3;
      content: "";
      width: 20px;
      height: 16px;
      top: calc(50% - 12px);
      position: absolute;
      border-radius: 50%;
      transition: 1s ease-in all;
      box-shadow: 0px 0px 1px 6px black;
    }
    &.color_radio_label::after {
      box-shadow: 0px 0px 0px 2px black;
    }
  }
  input[type="radio"]:checked + label.color_radio_label {
    color: attr(datacolor);
  }
  input[type="radio"]:focus + label {
    user-select: none;
  }
  &.mt-5px {
    margin-top: 5px;
  }
}

.p-0-col {
  padding: 0 !important;
}

.styled_notice {
  padding-left: 5px;
  padding-right: 5px;
}

.edit_table_style_toggle {
  border: 1px solid;
  right: 0px;
  top: 45px;
  min-width: 225px;
  width: 100%;
  height: 24px;
  padding: 1.5px;
  border: 0;
  &:not(.btn-secondary) {
    width: 100%;
    min-width: 225px;
  }
  &.left_side {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
    text-align: right;
  }
  &.right_side {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
    text-align: left;
  }
  &:hover {
    background: transparent;
  }
  outline: 0;
  &:active,
  &:focus {
    outline: 0;
    box-shadow: none;
  }
  &.bg-secondary-edit,
  &.bg-secondary-edit:focus,
  &.bg-secondary-edit:target {
    background: #ced2d8 !important;
  }
  &.bg-green-edit,
  &.bg-green-edit:focus,
  &.bg-green-edit:target {
    background: #2eb85c !important;
  }
}

.popover__message.d-flex {
  display: flex;
  .driver_location_info_icons {
    width: 30px;
    margin-right: 20px;
  }
  .driver_location_info-span {
    margin: auto 0;
    color: white;
  }
  .distance-icon {
    background: white;
    border-radius: 50%;
    box-shadow: 0px 0px 0px 4px white;
    transform: scale(0.8);
  }
}

.fixed_table.th-7-p-0 tr th:nth-of-type(7) {
  padding-right: 0;
}

.fixed_table.th-7-p-0 tr th:nth-of-type(8) {
  padding-left: 0;
}

.fixed_table.th-8-p-0 tr th:nth-of-type(8) {
  padding-right: 0;
}

.fixed_table.th-8-p-0 tr th:nth-of-type(9) {
  padding-left: 0;
}

.company_logo_wrapper img {
  max-height: 50px;
}

.upload-company-image-wrapper img {
  padding-left: 15px;
}

.fixed_table.load_ids table tbody tr td.max-width-100 {
  max-width: 100% !important;
}
.fixed_table.load_ids table tbody tr td.max-width-100 span {
  max-width: 100% !important;
}

.fixed_table table tbody tr td.bg-yellow span {
  padding: 0 2px !important;
  background: #fafe47;
}

.weather-today.flex-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.driver_location_info_icons.w-75px {
  width: 75px;
  max-width: 100%;
}

.rate_confirmation_generate_button {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 26px;
}

.fixed_table tbody td.p-relative {
  position: relative;
  .drivers_reference-info-span {
    margin-left: 15px;
  }
  .drivers_info_show_toggle {
    position: absolute;
    left: 0;
  }
}

.driver_info-octopus_logo-wrapper {
  display: flex;
  justify-content: right;
  width: 100%;
}

.driver_info-octopus_logo-wrapper button {
  background: transparent;
  border: 0;
  outline: transparent;
  .octopus_logo {
    max-width: 60px;
  }
}

.margin-top-40 {
  margin-top: 30px;
}

td.late_load span:not(:empty) {
  background-color: #2eb85c;
  padding: 0 5px;
}

.load_drivers_switch .c-switch[class*="-3d"].c-switch-lg {
  width: 60px;
  padding-left: 0;
}

.load_drivers_switch label {
  border-bottom: 0;
}

.edit_data_bordered-wrapper:not(.load_driver_edit_wrapper):not(.load_expenses_edit_wrapper):not(.tabs_exist)
  .card-body.pt-100px {
  padding-top: 100px !important;
}

.header_top_location_info {
  display: flex;
  justify-content: space-around;
  position: absolute;
  top: 30px;
  width: 100%;
}

span.location_no_info_notification {
  position: absolute;
  margin-top: -26px;
}

.table-filter-removed .c-datatable-filter {
  visibility: hidden;
}

.prices_summ_loads_page-wrapper {
  display: grid;
  justify-content: left;
  position: absolute;
}

.prices_summ_loads_page-wrapper span {
  display: inline;
  font-family: Barlow;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.03;
  letter-spacing: normal;
}
.prices_summ_loads_page-wrapper span:empty {
  display: none;
}
.table-price-filter-wrapper {
  position: relative;
}
.total_loads_prices, .total_statements_prices {
  position: absolute;
  justify-content: center;
  display: flex;
  margin-top: 3px;
  text-align: center;
  width: 100%;
}

.total_loads_prices span, .total_statements_prices span {
  margin-top: -3px;
  text-align: center;
}

.tr_after_as_header
  .table_custom_multi_header
  tbody
  tr[class]:not(.without_header)::after {
  content: attr(class);
}
.tr_after_as_header
  .table_custom_multi_header.drivers_activity
  tbody
  tr[class]:not(.without_header):not([class*="DriverInfo"])::after {
  content: attr(class) " | DriverInfo";
}
.tr_after_as_header
  .table_custom_multi_header
  tbody
  tr:nth-of-type(1)
  td::before {
  display: none;
}
.tr_after_as_header
  .table_custom_multi_header
  tbody
  tr:nth-of-type(1)
  td:after {
  top: 0 !important;
}
.tr_after_as_header .table_custom_multi_header tbody tr:nth-of-type(1) td {
  padding-top: 30px;
}

.tr_after_as_header .table_custom_multi_header tbody tr.DriverInfo td {
  padding-bottom: 30px;
}
.tr_after_as_header .table_custom_multi_header tbody tr.DriverInfo {
  position: relative;
}

.tr_after_as_header .table_custom_multi_header tbody tr.DriverInfo td {
  box-shadow: 0 0 0 2px white;
  background: linear-gradient(
    to top,
    #a9c0ff,
    #a9c0ff 25px,
    transparent 25px,
    transparent 100%
  );
}

// .tr_after_as_header .table_custom_multi_header tbody tr.DriverInfo::after {
//   content: '';
//   left: 0;
//   bottom: 1px;
//   width: 100%;
//   height: 24px;
//   position: absolute;
//   box-shadow: 0 0 0 2px white;
//   background-color: #A9C0FF;
// }
.tr_after_as_header
  .table_custom_multi_header
  tbody
  tr[class]:not(.without_header)::after {
  background-color: #495fda;
}
.table_custom_multi_header tbody tr.DriverInfo td div {
  position: relative;
  display: flex;
  z-index: 99;
  span {
    width: 100%;
    height: 100%;
  }
}
.table_custom_multi_header tbody tr[class] .summary_totals_span {
  bottom: -26px;
  position: absolute;
  font-weight: 700;
  font-size: 16px;
}

.drivers_activities_card .prices_summ_loads_page-wrapper .download_excell {
  margin-top: 24px;
  margin-left: 30px;
}

.fixed_table
  tbody
  tr
  td
  .btn-group
  > .btn.download_invoice_button:not(:first-child) {
  background-color: #2eb85c;
  width: 72px;
}

.fixed_table.table_thead_p-0 tr th {
  padding-bottom: 1px !important;
}

.top-5 {
  top: 5px;
}
.top-15 {
  top: -15px;
}
.tables-tabs-wrapper {
  position: relative;
}
.tables-tabs-wrapper .button_as_tab {
  top: 10px;
  position: absolute;
  padding: 5px 100px;
  height: 40px;
  border: 0;
  font-weight: 700;
}
.tables-tabs-wrapper .button_as_tab.inactive {
  background-color: #ebedef;
  color: #768192;
  box-shadow: 0px 0px 1px 1px #768192;
}
.tables-tabs-wrapper .button_as_tab.active {
  background-color: white;
  box-shadow: 0px 0px 1px 1px #768192;
  color: #4a60da;
  padding: 4px 99px;
  color: black;
}
.tables-tabs-wrapper .button_as_tab:nth-child(1) {
  left: calc(50% - 250px);
  border-top-left-radius: 25px !important;
}
.tables-tabs-wrapper .button_as_tab:nth-child(2) {
  right: calc(50% - 250px);
  border-top-right-radius: 25px !important;
}
.tables-tabs-wrapper .button_as_tab:focus,
.tables-tabs-wrapper .button_as_tab:active,
.tables-tabs-wrapper .button_as_tab:hover {
  outline: none;
  border: 0;
}

.cursor_pointer {
  cursor: pointer;
}

table tbody td:nth-of-type(6) table.LocationsAsTable tbody td:nth-of-type(1) {
  background-color: #2eb85c;
  color: #000;
  font-weight: 700;
  font-size: 16px;
}

table tbody td:nth-of-type(7) table.LocationsAsTable tbody td:nth-of-type(1) {
  background-color: #f9b115;
  color: #000;
  font-weight: 700;
  font-size: 16px;
}

.border-red-button {
  border: 2px solid red;
}
.border-blue-button {
  border: 2px solid blue;
}
.c-app.user_type4,
.c-app.user_type5 {
  .table-booked_by-dispatched_by_field.more_locations .more_locations_icon {
    top: 7px;
  }
  .fixed_table:not(.dispatch_table):not(.loads_as_table_copy) table tr td:not([class*="opened"]) {
    padding: 0 !important;
  }
  .fixed_table.loads_as_table_copy table tr td:not([class*="opened"]):not(:nth-of-type(6)):not(:nth-of-type(7)) {
    padding: 1px 2.5px !important;
  }
  .fixed_table table thead tr:not(:nth-child(2)) th svg {
    // display: none !important;
  }
  .fixed_table:not(.dispatch_table):not(.loads_as_table_copy) table tr:not(:nth-child(2)) th {
    padding: 5px 0 0 0;
  }
  .fixed_table .timeFilter_span.pu .react-datepicker-wrapper:not(:first-child) {
    display: none !important;
  }
  .fixed_table .timeFilter_span.pu .react-datepicker-wrapper ~ svg {
    display: none;
  }

  .fixed_table .timeFilter_span.del .react-datepicker-wrapper:first-child {
    // display: none !important;
  }
  .fixed_table .timeFilter_span.del .react-datepicker-wrapper ~ svg {
    // display: none;
  }
}

.tables-tabs-wrapper button.download_all_invoices:not(:hover):not(:disabled) {
  display: none;
  position: absolute;
  top: calc(100% + 47px);
  z-index: 99;
  right: 100%;
  width: 250px;
}

.tables-tabs-wrapper
  .button_as_tab:nth-child(1):hover
  ~ button.download_all_invoices,
.tables-tabs-wrapper button.download_all_invoices:hover,
.tables-tabs-wrapper button.download_all_invoices:disabled {
  display: block;
  position: absolute;
  top: calc(100% + 47px);
  padding: 8px 0;
  z-index: 99;
  right: 100%;
  width: 250px;
  color: white;
  outline: 0;
  border: 0;
  border-radius: 0 0 15px 15px;
}

.tables-tabs-wrapper
  .button_as_tab:nth-child(1):hover
  ~ button.download_all_invoices,
.tables-tabs-wrapper button.download_all_invoices:hover {
  background-color: black;
}

.tables-tabs-wrapper button.download_all_invoices:disabled {
  transition: all 1s ease;
}

.edit_data_bordered-wrapper:not(.load_driver_edit_wrapper):not(.load_expenses_edit_wrapper):not(.one_more_fields)
  input.input_with_formatted_value:not(:focus) {
  color: white !important;
}
.edit_data_bordered-wrapper:not(.load_driver_edit_wrapper):not(.load_expenses_edit_wrapper):not(.one_more_fields)
  input.input_with_formatted_value:focus
  ~ .formatted_input_value {
  display: none !important;
}

.input_with_formatted_value-wrapper {
  position: relative;
}
.input_with_formatted_value-wrapper .formatted_input_value {
  position: absolute;
  top: calc(50% - 18.5px);
  left: 21px;
}

span.desription_input {
  position: absolute;
  bottom: 2px;
  font-weight: 700;
}

.load_button_badge {
  margin-left: 2px;
  margin-bottom: 3px;
  border: 1px solid black;
  font-size: 16px;
  width: 100%;
  padding: 0;
  display: inline-flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
}
.load_button_badge p {
  margin: 0 0 0 2px;
}
.load_button_badge .btn-group {
  padding: 0 0 0 5px;
  margin-left: auto;
  margin-right: 0;
}
.load_button_badge .btn-group button {
  padding: 0 1px;
  z-index: 99;
  font-size: 16px !important;
}
.load_button_badge .btn-group button:focus {
  box-shadow: none;
}

.edit_driver_wrapper table img {
  max-width: 100px;
}
.editable_select_wrapper {
  min-width: 100px;
  padding: 0 !important;
}
.editable_select_wrapper div[class*="IndicatorsContainer"] {
  display: none;
}
.edit_data_bordered-wrapper .editable_select_wrapper div,
.edit_driver_wrapper
  .editable_select_wrapper
  div:not(.validation_required)
  div[class*="control"],
.edit_driver_wrapper .editable_select_wrapper div[class*="container"] {
  background: transparent !important;
  border: 0 !important;
  padding: 0 !important;
}

.editable_select_wrapper div[class*="menu"] {
  display: none;
}

.fixed_table.overflow-y-auto {
  overflow: scroll !important;
}
.fixed_table.overflow-y-auto.statements_table {
  transform: translate(10px, 0);
  padding: 40px 10px 0 10px !important;
  user-select: none;
}
.fixed_table.overflow-y-auto.statements_table .table-responsive {
  padding: 2px;
}
.fixed_table.overflow-y-auto.statements_table thead {
  position: sticky;
  font-size: 16px !important;
}
.fixed_table.overflow-y-auto.statements_table thead::before {
  width: 100%;
  position: absolute;
  bottom: 100%;
  content: "";
}
.fixed_table.overflow-y-auto.statements_table .table-responsive {
  overflow: initial;
}

.fixed_table.statements_table td,
.fixed_table.statements_table th {
  padding: 0;
  font-size: 16px;
  font-weight: 400;
}
.fixed_table.statements_table tr th,
.fixed_table.statements_table th div {
  text-align: center;
  padding: 0 1px !important;
}

.table_buttons {
  border: 0;
  width: 18px;
  height: 18px;
  background: #fff;
  padding: 2px;
  margin: auto 0 auto 15px;
  display: flex;
  flex-direction: column;
}
.table_buttons:active,
.table_buttons:hover,
.table_buttons:focus {
  outline: 0;
}
.table_buttons:hover {
  margin-top: 1px;
  height: 17px;
  box-shadow: 0px 0px 0px 2px white;
}

.table_buttons-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: right;
}

.c-app.c-default-layout.sidebar-set-on-hover
  .filter_toggled_wrapper
  .edit_data-header_info {
  font-size: 18px !important;
}

.statement-modal div[class*="menu"] {
  z-index: 100;
}

.statement-modal .modal-body {
  position: relative;
}

.statement-modal .modal-body .nav-pills:not(.t-0) {
  position: absolute;
  bottom: -50px;
}

.statement-modal .bills_modal_body.fixed_table table tbody tr td:first-of-type,
.statement-modal
  .bills_modal_body.fixed_table:not(.load_ids)
  table
  tbody
  tr
  td:first-of-type,
.statement-modal .bills_modal_body.fixed_table table tbody tr td span {
  max-width: max-content !important;
}
.statement-modal .bills_modal_body.fixed_table {
  max-height: calc(100vh - 100px);
}
.statement-modal .bills_modal_body {
  padding-top: 40px;
}
.statement-modal .modal-body .nav-pills.t-0 {
  position: absolute;
  top: 0;
}

.statement-modal .modal-body .nav-pills .nav-item .active {
  display: none;
}
.statement-modal .bills_modal_body .nav-pills .nav-item {
  display: contents;
}
.statement-modal
  .bills_modal_body
  .nav-pills
  .nav-item:not(:nth-child(2)):not(:nth-child(1))
  .nav-link:not(.disabled) {
  margin-left: 20px;
}
.statement-modal .modal-body .nav-pills .nav-item .nav-link:not(.disabled) {
  background-color: #321fdb;
  color: white;
}

.statement-modal .modal-body .modal-body-header {
  width: 90%;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  justify-content: center;
  display: flex;
  box-shadow: 0 0 2px 2px #d8dbe0;
  margin: 7px 0 4px 0;
}
.statement-modal .modal-body .editable_select_wrapper {
  width: 100% !important;
  padding: 0 15px !important;
}
.statement-modal .modal-body .editable_select_wrapper label {
  text-decoration: underline;
}
.statement-modal .modal-body .form-group button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.statement-modal .modal-body .form-group {
  margin: 2px 0 !important;
}
.statement-modal .modal-body .form-group input {
  border-radius: 0;
  cursor: pointer;
}
.statement-modal .modal-body .form-group input:focus {
  box-shadow: none;
}
.tr-row .row {
  margin: auto;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
}

.tr-row .row:nth-child(1) {
  border: 0.5px solid grey;
  margin-top: 5px;
  margin-bottom: 2px;
}
.tr-row .row:nth-last-child() {
  margin-bottom: 2px !important;
}

.fixed_table .c-datatable-filter {
  opacity: 0;
  visibility: hidden;
}
.fixed_table .remove_filters_button:not(:hover) {
  background-color: none !important;
  color: #7c8596 !important;
  border: 0 !important;
}
.fixed_table .remove_filters_button:hover {
  background-color: #26994d !important;
}
.fixed_table .remove_filters_button {
  top: 20px;
  left: 20px;
  position: absolute;
  cursor: pointer;
  padding-left: 45px !important;
  z-index: 10;
  background-color: black;
  background: url("/assets/funnel.png");
  background-size: 29px 29px;
  background-position: 5px 2px;
  background-repeat: no-repeat;
  font-weight: 600;
  letter-spacing: 1.5px !important;
}

.statements-inputs-group input:nth-child(1) {
  width: 100px;
}

.location_add_form .form-group div[class*="container"] div[class*="menu"] {
  z-index: 2;
}

.form_disabled .tab_locked_info {
  background-color: red;
}
.form_disabled .disable-part div[class*="modal"] {
  pointer-events: all;
}
.form_disabled .disable-part button,
.form_disabled .disable-part input,
.form_disabled .disable-part select {
  pointer-events: none;
  cursor: no-drop !important;
}

.form_disabled .form-group div[class*="Input"] input {
  background: transparent !important;
}
.form_disabled
  .input_with_formatted_value-wrapper
  #text-input.input_with_formatted_value,
.form_disabled
  .input_with_formatted_value-wrapper
  #text-input.input_with_formatted_value:not(:focus) {
  color: #ccc !important;
}
.form_disabled button,
.form_disabled footer .submit_button-tabs,
.form_disabled footer .reset_button-tabs,
.form_disabled .form-group input,
.form_disabled .form-group textarea,
.form_disabled .form-group select,
.form_disabled .form-group div[class*="control"] {
  background-color: #ccc !important;
  pointer-events: none !important;
}
.form_disabled .form-group:not(.all_users_can_change),
.form_disabled .button_switch-wrapper {
  pointer-events: none;
}

.pointer-all {
  pointer-events: all !important;
}

.pointer_events_none {
  pointer-events: none !important;
}

.files-document-link {
  display: flex;
  justify-content: center;
  flex-direction: column;
  svg {
    width: 80px;
    margin: 5px auto 5px auto;
  }
  font-weight: 700;
}

.row.split_load,
span.split_load {
  box-shadow: -0.5px 0.3px 3px 2px #321fdb;
}

.async_form_submit_button {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}
.async_form_submit_button span {
  margin: auto !important;
  display: flex;
}
.async_form_submit_button div {
  margin-left: 10px;
  width: 21px;
  height: 21px;
}
.async_form_submit_button:not(.loader_button) div {
  display: none;
}
.async_form_submit_button.loader_button {
  background-color: black;
  user-select: none;
  pointer-events: none;
}
.statement-table-buttons-group {
  display: flex;
  flex-direction: row;
}
.statement-table-buttons-group div {
  padding: 0 2px;
  width: 50px;
}
.statement-table-buttons-group div button {
  padding: 6px 0;
  height: 100%;
  width: 100%;
}
.statement-table-buttons-group div a {
  width: 100%;
}
.statement-table-buttons-group > div:nth-child(2) {
  display: flex;
  flex-direction: column;
}

.advance_desc_form_group {
  border: 1px solid green;
  align-items: center;
  padding: 2px 0 !important;
}

.btn-group .register_approve_button {
  width: 100px !important;
}

.location.swichable {
  border: 0 !important;
}

.c-switch[class*="-3d"].c-switch-lg.location
  .c-switch-input
  ~ .c-switch-slider {
  width: 50px;
}
.c-switch[class*="-3d"].c-switch-lg.location
  .c-switch-input:checked
  ~ .c-switch-slider::before {
  transform: translateX(calc(25px));
}

.header_absolute-wrapper:not(.locked_load) .load_number_header svg {
  display: none;
}

.locked_load .load_number_header.lock_icon {
  cursor: pointer;
  position: relative;
  padding-right: 10px;
}

.edit_driver_wrapper:not(.one_more_fields)
  .driver_files_header
  select::-ms-expand {
  display: none;
}
.edit_driver_wrapper:not(.one_more_fields) .driver_files_header {
  padding-top: 15px !important;
}
.edit_driver_wrapper:not(.one_more_fields) .driver_files_header select {
  background-color: transparent !important;
  color: white !important;
  background-image: linear-gradient(45deg, transparent 50%, white 50%),
    linear-gradient(135deg, white 50%, transparent 50%) !important;
  background-position: calc(100% - 30px), calc(100% - 15px) !important;
  background-size: 15px 25px, 15px 25px !important;
  background-repeat: no-repeat !important;
  min-width: 200px !important;
}
.edit_driver_wrapper:not(.one_more_fields) .driver_files_header select option {
  background-color: #392b4c !important;
}

.truck_files_table img {
  max-width: 100px;
  max-height: 100px;
}

.truck_files_table td {
  vertical-align: middle;
}

.img-m-50 {
  max-width: 50px;
  max-height: 50px;
}
.img-m-100 {
  max-width: 100px;
  max-height: 100px;
}

.justify-center.fixed-table .table thead th,
.justify-center.fixed-table .table tbody td {
  text-align: center;
  justify-content: center;
}

.capitalized-text {
  text-transform: capitalize;
}

.table_chart_list_group {
  display: flex;
  height: 100%;
  vertical-align: middle;
  justify-content: center;
}

.table_chart_list_group .list-group-item span:nth-child(1) {
  width: 70%;
}
.table_chart_list_group .list-group-item span:nth-child(2) {
  width: 30%;
}

span.dynamic_progress {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  height: 30px;
  background-color: data(data-color);
}

.chart-widget.p-relative {
  position: relative;
}
.chart-widget {
  height: calc(100% - 24px);
  min-height: 100px;
}

.chart_collapse_button .c-icon {
  width: 15px !important;
  height: 15px !important;
  margin: 0;
}
.chart_collapse_button {
  position: absolute;
  top: -3px;
  right: 25px;
  z-index: 9999;
  background-color: white;
  padding: 0;
  margin: 0 !important;
  width: 25px;
  height: 25px;
}

.c-app.user_type4
  .fixed_table:not(.dispatch_table)
  table
  tr
  td:not([class*="opened"]).progress-status-td,
.c-app.user_type5
  .fixed_table:not(.dispatch_table)
  table
  tr
  td:not([class*="opened"]).progress-status-td {
  padding-top: 5px !important;
  display: flex;
  vertical-align: center;
  align-items: center;
  justify-content: center;
}

.battery.progress {
  font-size: 8px;
  width: 26px;
  height: 45px;
  color: midnightblue;
  border: 1.5px solid;
  border-radius: 1.5px;
  position: relative;
  background-image: linear-gradient(
    to top,
    #2eb85c 0%,
    #2eb85c 20%,
    black 20%,
    black 100%
  );
  background-repeat: no-repeat;
  overflow: initial;
  display: flex;
  padding: 0;
  justify-content: center;
  flex-direction: column;
}
.battery.progress::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 2px;
  background-color: midnightblue;
  top: -4px;
  right: calc(50% - 10px);
  border-radius: 1.2px 1.2px 0 0;
}

.battery.progress p {
  margin: 0;
  color: white;
}
.LoadsAsTable-page table tr td{
  border: 0.5px solid;
}

.LoadsAsTable-page thead tr th input {
  display: none;
}

.locations_tab_body_table tbody td:nth-of-type(6) {
  font-weight: 600;
  font-size: 16px;
}
 
// .LoadsAsTable-page table tbody tr td:nth-of-type(10) {
//   min-width: 80px;
//   max-width: 80px !important;
// }

.LoadsAsTable-page table tbody tr td:nth-of-type(6) {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 14px !important;
  background-color: #EFF7EE;
}

.LocationsAsTable td, .LocationsAsTable th {
  &:last-of-type {
    border-right: none !important;
  }
  &:first-of-type {
    border-left: none !important;
  }
}

.LoadsAsTable-page table tbody tr td:nth-of-type(7) {
  padding-left: 0.5px !important;
  padding-right: 0 !important;
  padding-bottom: 14px !important;
  background-color: #EFF7EE;
}

.LoadsAsTable-page .fixed_table .card-body {
  margin-top: 35px;
}

.LocationsAsTable th {
  background-color: gray;
  color: white !important;
}

.LocationsAsTable td,
.LocationsAsTable th {
  border: 0.1px solid black !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 75px !important;
}
.LocationsAsTable td:last-child,
.LocationsAsTable th:last-child {
}
.LocationsAsTable td:nth-child(1),
.LocationsAsTable th:nth-child(1) {
  width: 150px !important;
  max-width: 150px !important;
  z-index: 1 !important;
}

.LoadsAsTable-page table tbody tr td:nth-of-type(10) textarea {
  height: 26px !important;
  min-height: 26px !important;
  max-height: 26px !important;
  overflow: hidden;
}

.LoadsAsTable-page table tbody tr td:nth-of-type(9), .LoadsAsTable-page table tbody tr td:nth-of-type(13) {
  font-weight: 700;
}

.header__multiselect {
  width: 200px !important;
  min-width: 200px !important;
  max-width: 200px !important;
  z-index: 9999;
  margin-left: 25px !important;
}

.header__multiselect div[class*="multiValue"]:nth-of-type(1) {
  margin-top: 10px;
}

.header__multiselect div[class*="multiValue"] >div {
  font-size: 14px !important;
}

.header__multiselect div[class*="ValueContainer"] {
  background-color: white;
}

.header__multiselect >div[class*="control"] {
  height: 40px !important;
  min-height: 40px;
  overflow: hidden;
}

.header__multiselect >div[class*="control"] > div:nth-child(2):before {
  top: 13px !important;
}

.header__multiselect:hover >div[class*="control"] {
  overflow: initial;
  background-color: white;
}

.header__multiselect div[class*="placeholder"] {
  top: 20px !important;
}

.header__multiselect div[class*="Input"] {
  max-height: 20px !important;
}

// .managementboard .LoadsAsTable-page .fixed_table thead th {
//   padding: 0 2px !important;
// }
// .managementboard .LoadsAsTable-page .fixed_table td.overflow-inherit {
//   // overflow: inherit !important;
//   // border: 0.15px solid black !important;
//   z-index: 0;
// }

.paginationasweek-select {
  padding: 2px 60px 2px 15px;
  font-size: 12px;
}

.c-app
  .managementboard
  .LoadsAsTable-page
  .fixed_table:not(.dispatch_table)
  table:not(.LocationsAsTable)
  tr
  td:not([class*="opened"]),
.c-app
  .managementboard
  .LoadsAsTable-page
  .fixed_table:not(.dispatch_table)
  table:not(.LocationsAsTable)
  tr
  td:not([class*="opened"]) {
  padding: 0.2px 1px !important;
  z-index: 0 !important;
  opacity: 0.99;
}
.c-app
  .managementboard
  .LoadsAsTable-page
  .fixed_table:not(.dispatch_table)
  table:not(.LocationsAsTable)
  tr
  td:not([class*="opened"])
  textarea,
.c-app
  .managementboard
  .LoadsAsTable-page
  .fixed_table:not(.dispatch_table)
  table:not(.LocationsAsTable)
  tr
  td:not([class*="opened"])
  textarea {
  min-width: 200px !important;
  height: 100% !important;
  min-height: 30px !important;
  line-height: 1;
  font-size: 11px !important;
  padding: 2px !important;
  &:not(:focus) {
    overflow: hidden !important;
  }
}

.style_changer_row {
  display: none;
}

.managementboard .LoadsAsTable-page tbody,
.managementboard .fixed_table thead th {
  font-size: 13px !important;
}
.managementboard
  .LoadsAsTable-page.style_changer_active
  .table-notes_field:hover
  div[data-tooltip] {
  padding-bottom: 10px;
}
.managementboard
  .LoadsAsTable-page.style_changer_active
  .table-notes_field:hover
  div[data-tooltip]
  .style_changer_row {
  display: flex;
}

.style_changer_active .fixed_table .remove_filters_button.style_changer_toggle,
.fixed_table .remove_filters_button.style_changer_toggle:hover {
  background-color: #26994d !important;
  color: white !important;
}
.fixed_table .remove_filters_button.style_changer_toggle {
  background: none !important;
  border: 1px solid #7c8596 !important;
  color: #7c8596 !important;
  padding: 1px 15px !important;
  font-size: 13px;
}

// .style_changer_row_wrapper {
//   display: none;
// }
.fixed_table table.loading_table tbody tr td {
  background: transparent !important;
}
.load_by_file_locations {
  margin-bottom: 5px;
  margin-top: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  padding: 8px;
  border-radius: 5px;
}
.fixed_table table.loading_table tbody tr:nth-child(odd) {
  background-color: #ededed !important;
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0.7) 55%,
      rgba(255, 255, 255, 0) 60%
    )
    transparent;
  font-size: 0 !important;
  height: 20px !important;
  margin: 5px 2px !important;
  width: 100px !important;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 3s loading ease-in infinite;
}

.fixed_table table.loading_table tbody tr:nth-child(even) {
  background-color: #ededed !important;
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 30%,
      #fefeff 40%,
      #fefeff 50%,
      rgba(255, 255, 255, 0) 55%
    )
    #ededed;
  font-size: 0 !important;
  height: 20px !important;
  margin: 5px 2px !important;
  width: 100px !important;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1.5s loading ease-in infinite;
}

.fixed_table table.loading_table thead {
  margin-bottom: 20px !important;
}

@keyframes loading {
  to {
    background-position-x: -15%;
  }
}

.table_filter-react_select_wrapper div[class*="control"],
.table_filter-react_select_wrapper div[class*="IndicatorsContainer"] {
  max-height: 20px !important;
  height: 20px !important;
}

.reports_page_time_filters_wrapper .react-datepicker-wrapper,
.reports_page_time_filters_wrapper,
.table-filter_datepicker {
  min-width: 90% !important;
}

.barlow_font_barlow {
  font-family: "Barlow" !important;
  font-size: 17px !important;
}

// -----------------Blurred Loader
.loader-blurred {
  position: absolute;
  z-index: 999;
  background: rgba(255, 255, 255, 0.6);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;

  > div {
    padding-bottom: 100px;
  }
}

//// ---------------- /trucks status badge
.trucks-status {
  .bg-white {
    color: #000;
  }

  .badge-white {
    background: #ccc;
  }
}

.c-sidebar .c-sidebar-nav-dropdown-items {
  transition: none;
}
/// -------------------------------------- Invoice
.invoice.action-buttons {
  button {
    box-shadow: 0 0 0 0.2rem rgba(81, 65, 224, 0.5);
    &.border-red-button {
      border-color: #218341;
    }
  }
}

/// -------------------------------------- Drivers activity
.drivers-activity-card-body {
  nav[aria-label="pagination"] {
    position: absolute;
    top: 25px;
    left: 200px;
  }
}

.button_table_filter {
  padding: 0 !important;
  height: 32px !important;
  width: 100% !important;
}

/// ------------------------------------ Broker Card
.edit_data_bordered-wrapper {
  .broker-card-padding {
    position: relative;
    top: -20px;
  }
}

.file_td a img {
  width: auto !important;
  max-width: 100% !important;
  cursor: pointer;
  max-height: 60px !important;
}

.notification_header {
  position: sticky;
  top: 0;
  z-index: 9999;
}

.company-name-select[id*="Kais"] div[class*="singleValue"] {
  color: #154161 !important;
}

.company-name-select[id*="Veteran"] div[class*="singleValue"] {
  color: #FBC000 !important;
}

.company-name-select[id*="FALCONS"] div[class*="singleValue"] {
  color: #7BA08F !important;
}

.fixed_table tbody tr td .company_KAIS_badge {
  padding: 2px 7px !important;
  // color: #154161 !important;
}

.fixed_table tbody tr td .company_VETERAN_badge {
  padding: 2px 7px !important;
  // color: #FBC000 !important;
}

.fixed_table tbody tr td .company_FALCON_badge {
  padding: 2px 7px !important;
  // color: #154161 !important;
}

.table-customer_field:not(.not-uppercase) {
  text-transform: uppercase;
}

.load-history-toggle {
  padding: 2px 15px;
  border-radius: 8px;
  width: auto !important;
  font-family: Barlow;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  box-shadow: none;
  border-color: transparent;
}
.accounting__load-history-wrapper.hidden {
  height: 0;
  width: 0;
  overflow: hidden;
}

select.company_KAIS, option.company_KAIS {
  background-color: #154161 !important;
}

select.company_VETERAN, option.company_VETERAN {
  background-color: #FBC000 !important;
}

select.company_FALCON, option.company_FALCON {
  background-color: #7BA08F !important;
}

.statement-table-wrapper .table .table-sm th {
  padding-bottom: 16px !important;
}

.invoices-page__tables-header {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.invoices-page__tables-header button {
  height: 30px !important;
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.invoices-page__tables-header .invoices-page__tables-header__filter-remove {
  position: initial;
  padding: 0 0 0 15px !important;
  width: 140px !important;
  background-position: 3px 2px;
  background-size: 15px 18px;
  margin-left: 30px;
}

td .d-flex-center {
  display: flex;
  justify-content: center;
}
.copy_buttons_wrapper {
  display: flex;
  flex-direction: row;
  gap: 3px;
  margin-left: 5px;
  button, button:active, button:focus, button:hover {
    padding: 0;
    border: none;
    outline: none;
    background: transparent;
  }
}
.generate_input_flex {
  display: flex;
  flex-direction: row;
}
.load_by_file__header >button {
  padding: 1px 6px;
  margin-bottom: 7px;
}

.copiedtext_wrapper {
  gap: 4px;
  height: 60px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: auto;
}

.copiedtext_wrapper .badge {
  height: 24px;
  padding-right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin: 0;
}

.copiedtext_wrapper .badge button {
  padding: 0;
  margin: 0;
}

.copiedtext_wrapper .badge button svg {
  margin: 3px !important;
}

#file-input {
  color: transparent !important;
  position: relative;
  width: 150px;
  padding: 0 !important;
  border: 0 !important;
  outline: 0 !important;
  background-color: green;
  &::-webkit-file-upload-button {
    visibility: hidden;
  }
  &:after {
    content: "Select File";
    position: absolute;
    top: calc(50% - 9.5px);
    left: calc(50% - 33px);
    font-size: 14px;
    color: white;
  }
}

.borderOnHalf {
  position: relative;
}

.borderOnHalf::after {
  content: '';
  width: 1px;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 0;
  background-color: black;
}

@media (max-width: 576px) {
  .invoices-page__tables-header {
    left: 0;
  }
  .invoices-page__tables-header .invoices-page__tables-header__filter-remove {
    width: 110px !important;
    background-size: 15px 12px;
  }
  .invoices-page__tables-header span, .buttons_count_1 .invoices-page__tables-header .download_excell {
    margin-left: 10px !important;
  }
}